export enum BusinessModel {
  B2B = 1,
  B2C = 2,
  B2B2C = 3,
}

export enum Digitalisation {
  Digital = 1,
  NonDigital = 2,
  Mixed = 3,
}

export enum CompanyStage {
  PreRevenue = 1,
  Growth = 2,
  Maturity = 3,
}

export enum FundingStage {
  PreSeed = 1,
  Seed = 2,
  SeriesA = 3,
  SeriesBAndOnwards = 4,
}

export enum Impact {
  ESG = 1,
  SDG = 2,
  ShariahCompliant = 3,
  WomenLed = 4,
}

export enum PartnershipBacked {
  Venture = 1,
  Accelerator = 2,
  RAndD = 3,
  Industry = 4,
}

export enum Presence {
  Local = 1,
  Regional = 2,
  Global = 3,
}

export class CampaignBusinessModel {
  businessModel: BusinessModel;
}

export class CampaignImpact {
  impact: Impact;
  campaignId?: number;
  id?: number;
}
