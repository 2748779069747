import { Component, Input } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LinkType } from '../../core/enum/link-type.enum';
import { TncModalComponent } from '../../pages/signup/tnc-modal/tnc-modal.component';
import { environment } from '../../../environments/environment';
import { APP_VERSION } from '../../core/services/constant';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() isExpandFooter: boolean;
  test: Date = new Date();
  footerItems = [
    {
      colTitle: 'company',
      items: [
        { title: 'pitchIN', linkType: LinkType.RouterLink, url: '/about-us' },
        {
          title: 'businessDirectory',
          linkType: LinkType.RouterLink,
          url: '/businesses/details',
        },
        //{ title: "theTeam", linkType: LinkType.RouterLink, url: '/about-us', fragment: "our-team" },
        //{ title: "partnership", linkType: LinkType.RouterLink, url: '/about-us', fragment: "our-partners" },
        {
          title: 'contactUs',
          linkType: LinkType.URL,
          url: 'https://support.pitchin.my/hc/en-us/requests/new',
          badge: 'emailUs',
          badgeIcon: 'fa fa-envelope-o',
          badgeAction: {
            linkType: LinkType.URL,
            url: 'mailto:support@pitchin.my',
          },
        },
        {
          title: 'careers',
          linkType: LinkType.URL,
          url: 'https://careers.pitchin.my',
        },
        {
          title: 'brandGuidelines',
          linkType: LinkType.RouterLink,
          url: '/brand-guidelines',
        },
        {
          title: 'equityReport2020',
          linkType: LinkType.URL,
          url: 'https://res.cloudinary.com/pitchin/image/upload/ecf/reports/pitchIN_Report_2020-v1.pdf',
        },
        {
          title: 'newsPress',
          linkType: LinkType.URL,
          url: 'https://news.pitchin.my',
        },
      ],
    },
    {
      colTitle: 'learn',
      items: [
        {
          title: 'learningPortal',
          linkType: LinkType.RouterLink,
          url: '/learn',
        },
        {
          title: 'faqs',
          linkType: LinkType.URL,
          url: 'https://support.pitchin.my/hc/en-us',
        },
        {
          title: 'academy',
          linkType: LinkType.URL,
          url: 'https://academy.pitchin.my',
        },
        //{ title: "investing&EquityCrowdfunding", linkType: LinkType.URL, url: 'https://support.pitchin.my/hc/en-us/sections/4413638279697-INVESTING-IN-AN-EQUITY-CROWDFUNDING-ECF-CAMPAIGN' },
        {
          title: 'feeStructure',
          linkType: LinkType.RouterLink,
          url: '/fee-structure',
        },
        {
          title: 'equityCrowdfunding',
          linkType: LinkType.RouterLink,
          url: '/learn/invest/how-it-works',
        },
        {
          title: 'secondaryMarket',
          linkType: LinkType.RouterLink,
          url: '/learn/trade/about-pitchin-secondary-trading-market',
        },
      ],
    },
    {
      colTitle: 'legalInfo',
      items: [
        {
          title: 'websiteTermsConditions',
          linkType: LinkType.RouterLink,
          url: 'website-terms-conditions',
        },
        {
          title: 'serviceTermsConditions',
          linkType: LinkType.RouterLink,
          url: 'service-terms-conditions',
        },
        {
          title: 'ieoTermsConditions',
          linkType: LinkType.RouterLink,
          url: 'ieo-terms-conditions',
        },
        {
          title: 'pstxTermsConditions',
          linkType: LinkType.RouterLink,
          url: 'pstx-terms-conditions',
        },
        {
          title: 'nomineeAgreement',
          linkType: LinkType.RouterLink,
          url: 'nominee-agreement',
        },
        {
          title: 'privacyPolicy',
          linkType: LinkType.RouterLink,
          url: 'privacy-policy',
        },
        {
          title: 'pdpaPolicy',
          linkType: LinkType.RouterLink,
          url: 'pdpa-policy',
        },
        {
          title: 'riskWarning',
          linkType: LinkType.RouterLink,
          url: 'risk-warning',
        },
      ],
    },
  ];
  currentYear = new Date().getFullYear();
  LinkType = LinkType;
  Version = APP_VERSION;
  environment = environment;

  constructor(private modalService: NgbModal) {}

  popupFile(url: number) {
    const popupOptions = {
      size: 'lg',
      centered: true,
      keyboard: false,
      windowClass: 'modal-full-screen',
    } as NgbModalOptions;

    const modalRef = this.modalService.open(TncModalComponent, popupOptions);
    modalRef.componentInstance.url = url;
  }
}
