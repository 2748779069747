import { Component, OnInit } from '@angular/core';
import { statistics } from '../../../../assets/static/stats';
import { PrismicService } from '../../../core/services/api/prismic.service';
import { LandingStatistics } from '../../../core/models/landing-page-statistics';

@Component({
  selector: 'app-stats-section',
  templateUrl: './stats-section.component.html',
  styleUrls: ['./stats-section.component.scss'],
})
export class StatsSectionComponent implements OnInit {
  stats: LandingStatistics;
  staticStats = statistics;

  constructor(private readonly prismicService: PrismicService) {}

  ngOnInit(): void {
    this.prismicService.getFrontPageStats().subscribe({
      next: (data) => {
        this.stats = data;
      },
    });
  }
}
