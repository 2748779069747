<div class="tw-mb-10">
  <div class="tw-flex tw-justify-between">
    <div>
      <div class="tw-mb-5 tw-flex tw-items-start tw-gap-1">
        <!-- ECF badge -->
        <product-badge></product-badge>

        <span
          *ngIf="campaign?.isPrivate && !campaign?.isInvited"
          class="p-badge p-badge--icon tw-cursor-pointer tw-bg-private-light !tw-bg-opacity-100 tw-text-private hover:!tw-bg-opacity-70"
          #tooltipTotalMarket="matTooltip"
          (click)="tooltipTotalMarket.toggle()"
          matTooltip="{{ 'common.inviteOnlyTooltip' | translate }}"
        >
          <p-icon name="lock" iconClass="badge-icon"></p-icon>
          {{ 'common.inviteOnly' | translate }}
        </span>

        <span
          *ngIf="campaign?.isPrivate && campaign?.isInvited"
          class="p-badge p-badge--icon tw-cursor-pointer tw-bg-private-lightest !tw-bg-opacity-100 tw-text-private hover:!tw-bg-opacity-70"
          #tooltipTotalMarket="matTooltip"
          (click)="tooltipTotalMarket.toggle()"
          matTooltip="{{ 'common.invitedTooltip' | translate }}"
        >
          <p-icon name="unlock" iconClass="badge-icon"></p-icon>
          {{ 'common.invited' | translate }}
        </span>
      </div>

      <!-- Logo, Brandname -->
      <app-thumbnail-group
        [size]="'lg'"
        [imageUrl]="campaign.company?.companyLogoUrl"
        [name]="campaign.company?.brandName"
        [description]="campaign.company?.tagline"
      >
      </app-thumbnail-group>
    </div>

    <!-- Socials -->
    <div class="tw-flex tw-gap-4 tw-pl-10">
      <div
        *ngIf="isLogin"
        class="tw-h-8 tw-w-8"
        [ngClass]="campaign.isFavourite ? 'pitchin-icon-yellow-star' : 'pitchin-icon-star'"
        (click)="handleSaveCampaign()"
      ></div>

      <div ngbDropdown placement="bottom-right">
        <div class="pitchin-icon-share no-caret tw-h-8 tw-w-8" ngbDropdownToggle></div>
        <div class="share-dropdown" ngbDropdownMenu>
          <div class="d-flex flex-row">
            <share-button class="col-auto btn-share-ws px-1" button="whatsapp"></share-button>
            <share-button class="col-auto px-1" button="facebook"></share-button>
            <share-button class="col-auto px-1" button="twitter"></share-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Sector tags -->
  <div class="tw-mt-5">
    <app-tag-list
      [tags]="campaign.company?.sectors"
      [partnershipBacked]="campaign.partnershipBacked"
      [campaignImpacts]="campaign.campaignImpacts"
    />
  </div>
</div>
