<div class="container-fluid">
  <ng-container *ngIf="campaign && investmentInfo">
    <div class="row">
      <div class="col-lg-12 d-flex py-5 title-panel border-bottom">
        <img
          [alt]="campaign.name"
          [src]="campaign.company?.companyLogoUrl || defaultLogo"
          class="campaign-logo"
        />
        <h2 class="font-weight-extrabold align-self-center mb-0">{{ campaign.name }}</h2>
      </div>
    </div>
    <div class="row py-5 invest-panel">
      <div class="col-lg-8">
        <h3 class="font-weight-extrabold">{{ 'investment.investmentAmount' | translate }}</h3>
        <div class="mb-3">{{ 'investment.paymentsProcessedImmediately' | translate }}</div>
        <div class="mb-3 border-bottom pb-4 mb-3">
          <div class="row text-black mb-2">
            <div class="col-lg-3 col-4">
              {{
                campaign?.shareType === ShareType.Ulp
                  ? ('investment.numberUnit' | translate: { number: 1 })
                  : ('investment.numberShare' | translate: { number: 1 })
              }}:
            </div>
            <div class="col-lg-9 col-8 font-weight-bold">
              {{ investmentInfo.pricePerShare | numberSuffix }}
            </div>
          </div>
          <div class="row text-black mb-2">
            <div class="col-lg-3 col-4">
              {{ 'investment.numberLot' | translate: { number: 1 } }}:
            </div>
            <div class="col-lg-9 col-8 font-weight-bold">
              {{ investmentInfo.sharePerLot | number }}
              {{
                campaign?.shareType === ShareType.Ulp
                  ? ('investment.units' | translate: { number: 1 })
                  : ('investment.shares' | translate)
              }}
            </div>
          </div>
          <div class="row text-black mb-2">
            <div class="col-lg-3 col-4">{{ 'investment.minLot' | translate }}:</div>
            <div class="col-lg-9 col-8 font-weight-bold">
              {{ investmentInfo.minimumLots | number }} {{ 'investment.lots_plural' | translate }}
            </div>
          </div>
          <div class="row text-black">
            <div class="col-lg-3 col-4">
              {{ 'investment.incrementalLot' | translate: { number: 1 } }}:
            </div>
            <div class="col-lg-9 col-8 font-weight-bold">
              {{ investmentInfo.incrementLot | number }} {{ 'investment.lots_plural' | translate }}
            </div>
          </div>
        </div>
        <form [formGroup]="form" [class.show-form-error]="showFormError" (ngSubmit)="submit()">
          <div class="input-panel">
            <label class="input-label" for="investmentLot">{{
              'investment.lot' | translate
            }}</label>
            <div class="input-field">
              <input
                type="text"
                class="form-control"
                formControlName="lotAmount"
                digitOnly
                [pattern]="OneBillionPattern"
                [class.error]="
                  form.controls.lotAmount.errors &&
                  (form.controls.lotAmount.errors.lessThanMin ||
                    form.controls.lotAmount.errors.incrementFalse)
                "
                (input)="calculateInvestmentAmount()"
                id="investmentLot"
              />
              <div
                class="error tw-mt-1.5 tw-py-2 tw-pr-5 tw-text-xs tw-text-primary empty:tw-hidden"
                *ngIf="form.controls.lotAmount.errors && form.controls.lotAmount.errors.lessThanMin"
              >
                {{ 'settings.lessThanMin' | translate: { min: investmentInfo.minimumLots } }}
              </div>
              <div
                class="error tw-mt-1.5 tw-py-2 tw-pr-5 tw-text-xs tw-text-primary empty:tw-hidden"
                *ngIf="
                  form.controls.lotAmount.errors && form.controls.lotAmount.errors.incrementFalse
                "
              >
                {{ 'settings.incrementFalse' | translate: { min: investmentInfo.incrementLot } }}
              </div>
            </div>
            <app-validation-error-message
              [inputName]="'investment.lot' | translate"
              [showFormError]="showFormError"
              [control]="form.get('lotAmount')"
            >
            </app-validation-error-message>
          </div>
          <div class="input-panel">
            <div class="input-label">{{ 'investment.investmentAmount' | translate }}</div>
            <div class="input-field">
              <div class="position-absolute font-size-smaller m-3">
                {{ 'investment.rm' | translate }}
              </div>
              <div class="form-control pl-5 grey-bg">
                {{ form.value.investmentAmount | number }}
              </div>
            </div>
          </div>
          <div *ngIf="campaign.shareTypes?.length > 1" class="input-panel">
            <div class="input-label">{{ 'investment.shareType' | translate }}</div>
            <section class="input-field">
              <mat-radio-group formControlName="shareType">
                <mat-radio-button
                  class="!tw-block tw-text-xl"
                  *ngFor="let shareType of campaign.shareTypes"
                  [value]="shareType"
                >
                  <div>
                    {{ 'offerCreation.' + ShareType[shareType] | translate }}
                  </div>
                </mat-radio-button>
              </mat-radio-group>
            </section>
            <app-validation-error-message
              [inputName]="'investment.shareType' | translate"
              [showFormError]="showFormError"
              [control]="form.get('shareType')"
            >
            </app-validation-error-message>
          </div>
          <div class="input-panel">
            <label class="input-label" for="referralCode">{{
              'investment.referralCode' | translate
            }}</label>
            <div class="input-field">
              <input
                type="text"
                class="form-control input-normal"
                [placeholder]="'investment.referralCodeOptional' | translate"
                formControlName="referralCode"
                [maxLength]="MaxReferalCodeLength"
                noSpace
                id="referralCode"
              />
            </div>
          </div>
          <h3 class="font-weight-extrabold mt-5 mb-3">{{ 'investment.terms' | translate }}</h3>
          <div class="term-container">
            <div class="checkbox-panel checkbox-field font-weight-bold d-flex">
              <mat-checkbox formControlName="isAcceptTerm1" class="checkbox">
                <div
                  class="align-top"
                  [innerHTML]="'investment.investmentTerms1' | translate"
                ></div>
              </mat-checkbox>
            </div>
            <div class="checkbox-panel checkbox-field font-weight-bold d-flex">
              <mat-checkbox formControlName="isAcceptTerm2" class="checkbox">
                <div
                  class="align-top"
                  [innerHTML]="'investment.investmentTerms2' | translate"
                ></div>
              </mat-checkbox>
            </div>
          </div>
          <div class="input-panel mt-4">
            <button class="btn btn-primary mt-5 font-size-larger" type="submit">
              {{ 'investment.confirmInvest' | translate }}
            </button>
          </div>
        </form>
      </div>
      <div class="col-lg-4">
        <div class="info font-size-larger">
          <div class="d-flex mb-4 ml-3">
            <div class="pitchin-icon pitchin-icon-info-icon mr-4"></div>
            <h3 class="font-weight-extrabold mb-0 align-self-center">
              {{ 'investment.info' | translate }}
            </h3>
          </div>
          <ol>
            <li class="mb-3">
              <span>
                {{ 'investment.fundingGoalDesc' | translate }}
                <a class="font-weight-extrabold">{{
                  investmentInfo.minimumTargetAmount | numberSuffix
                }}</a>
              </span>
            </li>
            <li class="mb-3">
              <span>
                {{ 'investment.minimumAmountPledgeDesc' | translate }}
                <a class="font-weight-extrabold">
                  {{
                    investmentInfo.minimumLots *
                      investmentInfo.sharePerLot *
                      investmentInfo.pricePerShare | numberSuffix
                  }}
                  ({{ investmentInfo.minimumLots }}
                  {{
                    (investmentInfo.minimumLots > 1 ? 'investment.lots' : 'investment.lot')
                      | translate
                  }})</a
                >
              </span>
            </li>
            <li class="mb-3">
              <span>
                {{ 'investment.incrementalPledgeDesc' | translate }}
                <a class="font-weight-extrabold">
                  {{
                    investmentInfo.incrementLot *
                      investmentInfo.sharePerLot *
                      investmentInfo.pricePerShare | numberSuffix
                  }}
                  ({{ investmentInfo.incrementLot }}
                  {{
                    (investmentInfo.minimumLots > 1 ? 'investment.lots' : 'investment.lot')
                      | translate
                  }})
                </a>
              </span>
            </li>
            <li class="mb-3">
              <span>{{ 'investment.yourPledgeAmountDesc' | translate }}</span>
            </li>
            <li class="mb-3">
              <span>{{ 'investment.issuerReservesTheRights' | translate }}</span>
            </li>
            <li>
              <span>{{ 'investment.investmentWillBeConfirmed' | translate }}</span>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </ng-container>
  <agmo-loading *ngIf="!campaign || !investmentInfo"></agmo-loading>
</div>
