import { Component } from '@angular/core';
import { MenuItem } from '../../../core/models/menu/menu-item';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-service-section',
  templateUrl: './service-section.component.html',
  styleUrls: ['./service-section.component.scss'],
})
export class ServiceSectionComponent {
  public environment = environment;

  menu: MenuItem[] = [
    {
      name: 'ecf',
      icon: 'pitchin-icon-invest-ecf',
      description: 'menu.ecfDescription',
      url: '/equity',
      className: 'tw-bg-ecf-light',
    },
    {
      name: 'pstx',
      icon: 'pitchin-icon-invest-pstx',
      description: 'menu.pstxDescription',
      url: '/pstx',
      className: 'tw-bg-pstx-light',
      show: () => {
        return environment.flagToShowPstx;
      },
    },
    {
      name: 'tcf',
      icon: 'pitchin-icon-invest-ieo',
      description: 'menu.ieoDescription',
      isExternal: true,
      url: environment.ieoBaseUrl,
      className: 'tw-bg-ieo-light',
    },
    {
      name: 'shariah',
      icon: 'pitchin-icon-invest-shariah',
      description: 'menu.shariahDescription',
      url: '/shariah-compliant-investment',
      className: 'tw-bg-shariah-light',
    },
  ];
}
