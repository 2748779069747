import { DOCUMENT, Location, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
// import { Location } from '@angular/common';
import { ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isSupported } from 'firebase/messaging';
import * as moment from 'moment-timezone';
import { environment } from '../environments/environment';
import { NavbarComponent } from './components/navbar/navbar.component';
import { DeviceType } from './core/enum/device-type.enum';
import { PushNotificationService } from './core/services/api/push-notification.service';
import { StorageService } from './core/services/storage.service';
// import { Redirect } from './core/models/redirect/redirect';
// import { RedirectList } from './core/services/redirect-list';
import { AuthService } from './core/services/auth/auth.service';
import { GtmDataLayerService } from './core/services/gtm-data-layer.service';
import { StatusCodeResponseService } from './core/services/status-code-response.service';
import { WindowReferenceService } from './core/services/window-reference.service';
import { HeaderService } from './shared/services/header/header.service';
import { ToastService } from './core/services/toast.service';
import { PostMessageService } from './core/services/post-message.service';
// import { LoggingService } from './core/services/logging.service';

// var didScroll;
let lastScrollTop = 0;
let delta = 5;
let isVisible = false;
// declare let fbq, gtag: Function;

@Component({
  selector: '[app-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(NavbarComponent) navBarComponent: NavbarComponent;

  private window;
  private _router: Subscription;
  showScrollUp = false;
  deviceType = DeviceType.Web;
  isExpandFooter = false;
  launcherExist: any;
  showUI = true;

  DeviceType: typeof DeviceType = DeviceType;

  constructor(
    private readonly renderer: Renderer2,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly windowRef: WindowReferenceService,
    private readonly element: ElementRef,
    public readonly location: Location,
    public readonly translate: TranslateService,
    private readonly storageService: StorageService,
    private readonly authService: AuthService,
    private readonly pushNotificationService: PushNotificationService,
    @Inject(DOCUMENT) private readonly document: any,
    @Inject(PLATFORM_ID) private readonly platform: Object,
    private readonly activatedRoute: ActivatedRoute,
    private readonly headerService: HeaderService,
    private readonly statusCodeResponseService: StatusCodeResponseService,
    private readonly _gtmDataLayerService: GtmDataLayerService,
    private readonly toastService: ToastService,
    private readonly postMessageService: PostMessageService,
    // private loggingService: LoggingService
  ) {
    this.window = windowRef.nativeWindow;
  }

  @HostListener('window:scroll', ['$event'])
  hasScrolled() {
    let st = window.pageYOffset;
    this.showScrollUp = st > 0;

    let launcher = document.getElementById('launcher');

    if (launcher) {
      launcher.style.bottom =
        this.showScrollUp && this.isExpandFooter && screen.width < 768 ? '89px' : '0px';
    }

    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - st) <= delta) return;

    let navbar = document.getElementsByTagName('header')[0];
    let campaignNavbar = document.getElementById('tabList');

    // If they scrolled down and are past the navbar, add class .headroom--unpinned.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && this.getPosition(navbar) <= 0) {
      // Scroll Down
      if (navbar.classList.contains('headroom--pinned')) {
        navbar.classList.remove('headroom--pinned');
        navbar.classList.add('headroom--unpinned');
      }

      this.navBarComponent.closeDropdown();
      // $('.navbar.headroom--pinned').removeClass('headroom--pinned').addClass('headroom--unpinned');
    } else {
      // Scroll Up
      //  $(window).height()
      if (st + window.innerHeight < document.body.scrollHeight) {
        // $('.navbar.headroom--unpinned').removeClass('headroom--unpinned').addClass('headroom--pinned');
        // When the campaignNavbar is visible in the viewport, avoid the navbar to be pinned
        // Force to pin when it hits the top
        if (
          st <= delta ||
          (!this.isElementVisibleInViewport(campaignNavbar) &&
            navbar.classList.contains('headroom--unpinned'))
        ) {
          navbar.classList.remove('headroom--unpinned');
          navbar.classList.add('headroom--pinned');
        } else if (
          this.isElementVisibleInViewport(campaignNavbar) &&
          navbar.classList.contains('headroom--pinned')
        ) {
          navbar.classList.remove('headroom--pinned');
          navbar.classList.add('headroom--unpinned');
        }
        // Force to pin when it hits the top
        // else if (st <= delta) {
        //     navbar.classList.remove('headroom--unpinned');
        //     navbar.classList.add('headroom--pinned');
        // }
      }
    }

    lastScrollTop = st;
  }

  ngOnInit() {
    this.initNavigation();
    this.initLanguage();
    this.setHeaderTag();

    if (isPlatformBrowser(this.platform)) {
      this.hasScrolled();
      this.initDevice();
      this.initTimezone();
      this.initGoogleTag();
    }

    // now will be handle from cloudfare, but remain the code for future used
    // this.initRouteCheck();

    this.syncLocalStorageWithTcf();
  }

  syncLocalStorageWithTcf() {
    this.postMessageService.syncLocalStorage();
  }

  isElementVisibleInViewport(el) {
    if (!el) return false;

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.boundingClientRect.top <= 5) {
          // Element is now sticks on the top
          isVisible = true;
        } else {
          isVisible = false;
        }
      });
    }, options);

    observer.observe(el);

    return isVisible;
  }

  getPosition(element) {
    if (!element) return 0;

    const rect = element?.getBoundingClientRect();
    return rect.top;
  }

  initNavigation() {
    let navbar: HTMLElement = this.element.nativeElement.children[1].children[0];

    this._router = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // Will update the page title (based on what set in `app.routing.ts`)
        // Unless being overrided by individual page
        this.setPageTitle();

        if (isPlatformBrowser(this.platform)) {
          this.showUI = location.pathname != '/redirect-login';
          this.isExpandFooter = this.activatedRoute.root.firstChild.snapshot.data.expandFooter;

          if (window.outerWidth > 991) window.document.children[0].scrollTop = 0;
          else window.document.activeElement.scrollTop = 0;

          // Log all the virtual PageView for all pages, except for those that handle `pageTitle`
          // outside from `app.routing.ts`, as it will be log separately
          this.logPageView();
        }

        this.renderer.listen('window', 'scroll', (event) => {
          const number = window.scrollY;
          if (number > 150 || window.pageYOffset > 150) {
            // add logic
            navbar.classList.add('headroom--not-top');
          } else {
            // remove logic
            navbar.classList.remove('headroom--not-top');
          }
        });
      });
  }

  ngOnDestroy() {
    if (this.launcherExist) clearInterval(this.launcherExist);

    this.postMessageService.cleanUp();
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platform)) {
      this.initFirebase();
    }
  }

  private initLanguage(): void {
    this.translate.addLangs(['en-US', 'zh-CN']);
    this.translate.setDefaultLang('en-US');
  }

  private initDevice(): void {
    if (!this.storageService.deviceId)
      this.storageService.deviceId = Math.random().toString(36).substr(2);

    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) this.deviceType = DeviceType.iOS;
    else if (/Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
      this.deviceType = DeviceType.Android;
  }

  private initTimezone(): void {
    moment.tz.setDefault(this.storageService.timeZone || moment.tz.guess());
  }

  private async initFirebase() {
    // reference: https://stackoverflow.com/questions/56215286/firebase-messaging-is-not-supported-in-your-browser-how-to-solve-this
    const isDeviceSupported = await isSupported();

    if (isDeviceSupported) {
      try {
        this.pushNotificationService.requestPermission();
        this.pushNotificationService.listen();
      } catch (e) {
        console.error(e);
      }
    }
  }

  scrollUp() {
    window.scroll({ top: 0, behavior: 'smooth' });
  }

  // initRouteCheck() {
  //     this.router.events.subscribe((event: Event) => {
  //         if (event instanceof NavigationStart) {
  //             let redirects: Redirect[] = RedirectList;
  //             redirects.forEach(redirect => {
  //                 if (event.url == redirect.fromUrl) {
  //                     this.router.navigate([redirect.toUrl]);
  //                 }

  //                 if (redirect.slugs && redirect.slugs.length > 0) {
  //                     let urls = event.url.toString().split("/").filter((element): element is string => {
  //                         return element !== null && element !== "";
  //                     });

  //                     if (('/' + urls[0] + '/' == redirect.fromUrl) && redirect.slugs.includes(urls[1])) {
  //                         let redirectUrl = redirect.toUrl + urls[1];
  //                         this.statusCodeResponseService.setRedirect(redirectUrl);
  //                         this.router.navigate([redirectUrl]);
  //                     }
  //                 }
  //             });
  //         }

  //         if (event instanceof NavigationError) {
  //             console.log(event.error);
  //         }
  //     });
  // }

  setHeaderTag() {
    let logoUrl = `${environment.baseUrl}/assets/img/logo/pitchIN_share_image.png`;
    this.headerService.updatePropertyTag('og:image', logoUrl);
    this.headerService.updateNameTag('twitter:image', logoUrl);
    this.headerService.updateNameTag('image', logoUrl);

    if (environment.environment != 'PRODUCTION') {
      if (!!this.headerService.getTag("content='noindex'"))
        this.headerService.updateNameTag('robots', 'noindex');
      else this.headerService.addTag('robots', 'noindex');

      if (!!this.headerService.getTag("content='nosnippet'"))
        this.headerService.updateNameTag('robots', 'nosnippet');
      else this.headerService.addTag('robots', 'nosnippet');
    } else {
      this.headerService.removeTag("name='robots'");
    }
  }

  initGoogleTag() {
    if (environment.environment == 'PRODUCTION') {
      let gtag = this.window.dataLayer;

      if (!gtag) {
        // (When No Javascript enabled)
        const noscript = document.createElement('noscript');
        noscript.innerHTML = `
                    <iframe src="https://www.googletagmanager.com/ns.html?id=${environment.googleTagId}"
                    height="0" width="0" style="display:none;visibility:hidden"></iframe>
                `;
        document.body.prepend(noscript);
      }
    }
  }

  getCurrentRouteData(route: ActivatedRouteSnapshot) {
    while (route.firstChild) {
      route = route.firstChild;
    }

    return route.data;
  }

  setPageTitle() {
    let routeData = this.getCurrentRouteData(this.route.snapshot);

    // Will update the `title` when it exists in `app.routing.ts`
    if (routeData?.title) {
      let name = () => {
        if (routeData.prependCompanyNameIntoTitle) {
          return `pitchIN | ${routeData.title}`;
        }

        return `${routeData.title} | pitchIN`;
      };

      this.headerService.setHeaderTag(name());
    }
  }

  logPageView() {
    let routeData = this.getCurrentRouteData(this.route.snapshot);

    if (routeData?.title) {
      this._gtmDataLayerService.logPageView(
        this.router.url,
        document.title,
        undefined,
        routeData.gtmContentGroup,
      );
    }
  }
}
