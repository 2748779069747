import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Sector } from '../../../core/models/company/sector';
import { SectorList } from '../../../core/models/company/sector-list';

@Component({
  selector: 'app-select-search-filter',
  templateUrl: './select-search-filter.component.html',
  styleUrls: ['./select-search-filter.component.scss'],
})
export class SelectSearchFilterComponent implements OnInit, OnChanges {
  @Input() list: SectorList;
  @Input() selectedList: any[] = [];
  @Input() placeholder: string;
  @Input() sectorListLabel: string;
  @Input() max: number;
  @Output() goSearch = new EventEmitter<string>();
  @Output() addSector = new EventEmitter<number>();
  @Output() deleteSector = new EventEmitter<number>();

  focus: boolean = false;
  isLoading: boolean = false;
  searchCtrl = new UntypedFormControl();
  popularSectors: Sector[];
  commonSectors: Sector[];

  constructor() {}

  ngOnInit() {
    this.searchCtrl.valueChanges.pipe(debounceTime(1000)).subscribe(() => {
      if (this.searchCtrl.value || this.searchCtrl.value == '') {
        this.isLoading = true;
        this.goSearch.emit(this.searchCtrl.value.trim());
      }
    });
  }

  ngOnChanges() {
    this.setList();
  }

  setList() {
    if (this.list) {
      this.commonSectors = this.list.commonSectors;
      this.popularSectors = this.list.popularSectors;

      if (this.selectedList && this.selectedList.length > 0) {
        this.selectedList.forEach((selected) => {
          this.commonSectors =
            this.commonSectors?.length > 0
              ? this.commonSectors.filter(function (item) {
                  return item.id !== selected.id;
                })
              : [];

          this.popularSectors =
            this.popularSectors?.length > 0
              ? this.popularSectors.filter(function (item) {
                  return item.id !== selected.id;
                })
              : [];
        });
      }
    }

    this.isLoading = false;
  }

  addItem(item) {
    this.selectedList.push(item);
    this.searchCtrl.setValue('');
    setTimeout(() => (this.commonSectors = []), 200);
    setTimeout(() => (this.popularSectors = []), 200);
    this.addSector.emit(item.id);
  }

  deleteItem(id: number) {
    this.selectedList.splice(
      this.selectedList.findIndex((x) => x.id === id),
      1,
    );
    this.deleteSector.emit(id);
  }

  onfocus() {
    if (this.max && this.selectedList.length >= this.max) {
      return;
    }
    this.focus = true;
    this.setList();
  }

  onblur() {
    this.searchCtrl.setValue('');
    setTimeout(() => (this.focus = false), 200);
    setTimeout(() => (this.commonSectors = []), 200);
    setTimeout(() => (this.popularSectors = []), 200);
  }
}
