import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { CampaignFormComponentType } from './core/enum/device-type.enum copy';
import { FormType } from './core/enum/form-type.enum';
import { Role } from './core/enum/role.enum';
import { AuthGuard } from './core/services/auth/guard.service';
import { InternalGuard } from './core/services/internal-guard.service';
import { MysecLayoutComponent } from './layouts/mysec-layout/mysec-layout.component';
import { ProfileComponent } from './layouts/profile/profile.component';
import { AboutPitchinComponent } from './pages/about-pitchin/about-pitchin.component';
import { EquityCampaignComponent } from './pages/equity-campaign/equity-campaign.component';
import { EquityCrowdfundingComponent } from './pages/equity-crowdfunding/equity-crowdfunding.component';
import { FeeStructureComponent } from './pages/fee-structure/fee-structure.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { BusinessDirectoryComponent } from './pages/landing/business-directory/business-directory.component';
import { CompanyDetailsComponent } from './pages/landing/business-directory/company-details/company-details.component';
import { EddFormComponent } from './pages/landing/campaign-details/edd-form/edd-form.component';
import { ChoosePaymentComponent } from './pages/landing/campaign-details/invest-now/choose-payment/choose-payment.component';
import { PaymentReceiptComponent } from './pages/landing/campaign-details/invest-now/payment-receipt/payment-receipt.component';
import { InvestNowComponent } from './pages/landing/campaign-details/invest-now/invest-now.component';
import { LiveCampaignsComponent } from './pages/landing/campaign-status/live-campaigns/live-campaigns.component';
import { SuccessCampaignsComponent } from './pages/landing/campaign-status/success-campaigns/success-campaigns.component';
import { LandingComponent } from './pages/landing/landing.component';
import { BeyondFourComponent } from './pages/landing/partners/beyond-four/beyond-four.component';
import { MrantiComponent } from './pages/landing/partners/mranti/mranti.component';
import { RiskWarningComponent } from './pages/landing/risk-warning/risk-warning.component';
import { LearnArticleComponent } from './pages/learn/learn-article/learn-article.component';
import { LearnPreviewComponent } from './pages/learn/learn-preview/learn-preview.component';
import { LearnComponent } from './pages/learn/learn.component';
import { IeoTermsConditionsComponent } from './pages/legal/ieo-terms-conditions/ieo-terms-conditions.component';
import { NomineeAgreementComponent } from './pages/legal/nominee-agreement/nominee-agreement.component';
import { PersonalDataProtectionComponent } from './pages/legal/personal-data-protection/personal-data-protection.component';
import { PrivacyPolicyComponent } from './pages/legal/privacy-policy/privacy-policy.component';
import { ServiceTermsConditionsComponent } from './pages/legal/service-terms-conditions/service-terms-conditions.component';
import { PstxTermsConditionsComponent } from './pages/legal/pstx-terms-conditions/pstx-terms-conditions.component';
import { WebsiteTermsConditionsComponent } from './pages/legal/website-terms-conditions/website-terms-conditions.component';
import { LoginBehalfRedirectionComponent } from './pages/login-behalf-redirection/login-behalf-redirection.component';
import { LoginComponent } from './pages/login/login.component';
import { CampaignInvestorListComponent } from './pages/my-campaign/campaign-investor-list/campaign-investor-list.component';
import { MyCampaignComponent } from './pages/my-campaign/my-campaign.component';
import { MysecSettingsComponent } from './pages/mysec/mysec-settings/mysec-settings.component';
import { AddTeamMemberComponent } from './pages/mysec/mysec-settings/team-management/add-team-member/add-team-member.component';
import { RayaWithPitchinComponent } from './pages/news/raya-with-pitchin/raya-with-pitchin.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { ApplicationFormComponent } from './pages/raise/application-form/application-form.component';
import { CampaignDraftRejectComponent } from './pages/raise/campaign-draft-reject/campaign-draft-reject.component';
import { CampaignDraftComponent } from './pages/raise/campaign-draft/campaign-draft.component';
import { CampaignFormComponent } from './pages/raise/campaign-form/campaign-form.component';
import { CreateCampaignDetailsComponent } from './pages/raise/create-campaign-details/create-campaign-details.component';
import { MakePaymentComponent } from './pages/raise/make-payment/make-payment.component';
import { RaiseFundReceiptComponent } from './pages/raise/make-payment/raise-fund-receipt/raise-fund-receipt.component';
import { MyApplicationComponent } from './pages/raise/my-application/my-application.component';
import { NewApplicationComponent } from './pages/raise/new-application/new-application.component';
import { OfferCreationComponent } from './pages/raise/offer-creation/offer-creation.component';
import { RaiseComponent } from './pages/raise/raise.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { EKycInProgressComponent } from './pages/signup/e-kyc-in-progress/e-kyc-in-progress.component';
import { EKycResultComponent } from './pages/signup/e-kyc-result/e-kyc-result.component';
import { ProceedEKycComponent } from './pages/signup/proceed-e-kyc/proceed-e-kyc.component';
import { RegisterCorporateComponent } from './pages/signup/register-corporate/register-corporate.component';
import { RegisterEmailComponent } from './pages/signup/register-email/register-email.component';
import { RegisterInvestorComponent } from './pages/signup/register-investor/register-investor.component';
import { RegisterIssuerComponent } from './pages/signup/register-issuer/register-issuer.component';
import { SignupComponent } from './pages/signup/signup.component';
import { VerifyEmailComponent } from './pages/signup/verify-email/verify-email.component';
import { StyleGuideComponent } from './pages/style-guide/style-guide.component';
import { WhyInvestComponent } from './pages/why-invest/why-invest.component';
import { BrokuComponent } from './pages/landing/partners/broku/broku.component';
import { WomenInTechComponent } from './pages/landing/partners/women-in-tech/women-in-tech.component';
import { InvestorDashboardComponent } from './layouts/investor-dashboard/investor-dashboard.component';
import { MyPortfolioComponent } from './pages/my-portfolio/my-portfolio.component';
import { WatchTowerAndFriendsComponent } from './pages/landing/partners/watch-tower-and-friends/watch-tower-and-friends.component';
import { EnableTwoFactorAuthComponent } from './pages/settings/security/enable-two-factor-auth/enable-two-factor-auth.component';
import { BackupCodesComponent } from './pages/settings/security/backup-codes/backup-codes.component';
import { RemoveTwoFactorAuthComponent } from './pages/settings/security/remove-two-factor-auth/remove-two-factor-auth.component';
import { PartnerComponent } from './pages/partner/partner.component';
import { ShariahCompliantInvestmentComponent } from './pages/shariah-compliant-investment/shariah-compliant-investment.component';
import { UmInnovationsComponent } from './pages/landing/partners/um-innovations/um-innovations.component';
import { ZFounderComponent } from './pages/landing/partners/z-founder/z-founder.component';
import { BrandGuidelinesComponent } from './pages/brand-guidelines/brand-guidelines.component';

const routes: Routes = [
  { path: 'preview', component: LearnPreviewComponent },
  {
    path: '',
    component: LandingComponent,
    data: {
      title: 'Invest in private companies via Equity Crowdfunding',
      isList: false,
      prependCompanyNameIntoTitle: true,
    },
  },
  {
    path: 'equity',
    component: LiveCampaignsComponent,
    data: { title: 'Equity Crowdfunding investment opportunities' },
  },
  //{ path: 'businesses', component: CampaignSectionComponent, data: { title: 'Open investment opportunities', isList: true, gtmContentGroup: 'Campaign' } },
  {
    path: 'equity/success',
    component: SuccessCampaignsComponent,
    data: { title: 'Successfully funded equity crowdfunding campaigns' },
  },
  {
    path: 'equity/invest',
    component: WhyInvestComponent,
    data: { title: 'Why invest in Equity Crowdfunding' },
  },
  { path: 'equity/:slug', component: EquityCampaignComponent, data: { expandFooter: true } },
  {
    path: 'equity/:slug/edd/:investmentId/:investmentEddId',
    component: EddFormComponent,
    data: { title: 'EDD Form', roles: [Role.IndividualInvestor, Role.CompanyRep] },
    canActivate: [AuthGuard],
  },
  {
    path: 'equity/:slug/invest-now',
    component: InvestNowComponent,
    data: { title: 'Invest Now', roles: [Role.IndividualInvestor, Role.CompanyRep] },
    canActivate: [AuthGuard],
  },
  {
    path: 'equity/:slug/invest-now/payment',
    component: ChoosePaymentComponent,
    data: { title: 'Choose Payment', roles: [Role.IndividualInvestor, Role.CompanyRep] },
    canActivate: [AuthGuard],
  },
  {
    path: 'equity/:slug/invest-now/payment/:investmentId',
    component: ChoosePaymentComponent,
    data: { title: 'Choose Payment', roles: [Role.IndividualInvestor, Role.CompanyRep] },
    canActivate: [AuthGuard],
  },
  {
    path: 'equity/:slug/investor-list',
    component: CampaignInvestorListComponent,
    data: { title: 'Investor List' },
    canActivate: [AuthGuard],
  },
  { path: 'businesses/details/:companySlug', component: CompanyDetailsComponent },
  {
    path: 'businesses/details',
    component: BusinessDirectoryComponent,
    data: { title: 'Business Directory' },
  },
  {
    path: 'raise',
    component: RaiseComponent,
    data: { title: 'Raise funds and capital via Equity Crowdfunding' },
  },
  {
    path: 'raise/raise-fund-form',
    component: ApplicationFormComponent,
    data: { title: 'Application Form', type: FormType.Fixed, roles: [Role.IssuerRep] },
    canActivate: [AuthGuard],
  },
  {
    path: 'raise/new-application',
    component: NewApplicationComponent,
    data: { title: 'New Application', roles: [Role.IssuerRep] },
    canActivate: [AuthGuard],
  },
  {
    path: 'raise/my-application',
    component: MyApplicationComponent,
    data: { title: 'My Application', roles: [Role.IssuerRep] },
    canActivate: [AuthGuard],
  },
  {
    path: 'raise/my-application/detail/:fundRaiseApplicationId/steps/:stepId/submission/:submissionId',
    component: ApplicationFormComponent,
    data: { title: 'Application Form', type: FormType.Submission, roles: [Role.IssuerRep] },
    canActivate: [AuthGuard],
  },
  {
    path: 'raise/my-application/form/:fundRaiseApplicationId/steps/:stepId',
    component: ApplicationFormComponent,
    data: { title: 'Application Form', type: FormType.Dynamic, roles: [Role.IssuerRep] },
    canActivate: [AuthGuard],
  },
  {
    path: 'raise/my-application/form/:fundRaiseApplicationId/steps/:stepId/submission/:submissionId',
    component: ApplicationFormComponent,
    data: { title: 'Application Form', type: FormType.Rejected, roles: [Role.IssuerRep] },
    canActivate: [AuthGuard],
  },
  {
    path: 'raise/my-application/make-payment/:fundRaiseApplicationId',
    component: MakePaymentComponent,
    data: { title: 'Make Payment', roles: [Role.IssuerRep] },
    canActivate: [AuthGuard],
  },
  {
    path: 'raise/offer-creation/:fundRaiseApplicationId',
    component: OfferCreationComponent,
    data: { title: 'Offer Creation', roles: [Role.IssuerRep] },
    canActivate: [AuthGuard],
  },
  {
    path: 'raise/new-campaign/:fundRaiseApplicationId',
    component: CampaignFormComponent,
    data: { title: 'New Campaign', type: CampaignFormComponentType.New, roles: [Role.IssuerRep] },
    canActivate: [AuthGuard],
  },
  {
    path: 'raise/edit-campaign/:fundRaiseApplicationId/:campaignId',
    component: CampaignFormComponent,
    data: { title: 'Edit Campaign', type: CampaignFormComponentType.Edit, roles: [Role.IssuerRep] },
    canActivate: [AuthGuard],
  },
  {
    path: 'raise/campaign-details/:fundRaiseApplicationId/:campaignId',
    component: CreateCampaignDetailsComponent,
    data: { title: 'Campaign Details', expandFooter: true, roles: [Role.IssuerRep] },
    canActivate: [AuthGuard],
  },
  { path: 'sign-up', component: SignupComponent, data: { title: 'Sign Up', isOAuth: false } },
  { path: 'sign-up/social', component: SignupComponent, data: { title: 'Sign Up', isOAuth: true } },
  {
    path: 'sign-up/register/:role',
    component: RegisterEmailComponent,
    data: { title: 'Register Email' },
  },
  {
    path: 'sign-up/register/:role/investor/:email/:token',
    component: VerifyEmailComponent,
    data: { title: 'Register Investor' },
  },
  {
    path: 'sign-up/proceed-e-kyc',
    component: ProceedEKycComponent,
    data: { title: 'Proceed eVerification', isProfile: false },
  },
  { path: 'e-kyc-result', component: EKycResultComponent, data: { title: 'eVerification Result' } },
  {
    path: 'e-kyc-in-progress',
    component: EKycInProgressComponent,
    data: { title: 'eVerification In Progress' },
  },
  {
    path: 'sign-up/individual-investor',
    component: RegisterInvestorComponent,
    data: { title: 'Register Individual Investor' },
    canActivate: [AuthGuard],
  },
  {
    path: 'sign-up/company-rep',
    component: RegisterCorporateComponent,
    data: { title: 'Register Corporate Investor' },
    canActivate: [AuthGuard],
  },
  {
    path: 'sign-up/issuer-rep',
    component: RegisterIssuerComponent,
    data: { title: 'Register Issuer' },
    canActivate: [AuthGuard],
  },
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: { title: 'Forgot Password' },
  },
  {
    path: 'reset-password/:email/:token',
    component: ResetPasswordComponent,
    data: { title: 'Reset Password' },
  },
  {
    path: 'fpx-receipt/:transactionId/:receiptType',
    component: PaymentReceiptComponent,
    data: { title: 'Payment Receipt' },
    canActivate: [AuthGuard],
  },
  {
    path: 'duitnow-receipt/:transactionId/:receiptType',
    component: PaymentReceiptComponent,
    data: { title: 'Payment Receipt' },
    canActivate: [AuthGuard],
  },
  {
    path: 'raise-fund-receipt/:transactionId',
    component: RaiseFundReceiptComponent,
    data: { title: 'Raise Fund FPX Receipt' },
    canActivate: [AuthGuard],
  },
  { path: 'risk-warning', component: RiskWarningComponent, data: { title: 'Risk Warning' } },
  {
    path: 'equity-crowdfunding',
    component: EquityCrowdfundingComponent,
    data: { title: 'Equity Crowdfunding' },
  },
  { path: 'fee-structure', component: FeeStructureComponent, data: { title: 'Fee Structure' } },
  { path: 'about-us', component: AboutPitchinComponent, data: { title: 'About pitchIN' } },
  {
    path: '404',
    component: NotFoundPageComponent,
    data: { title: '404 Page Not Found', isImage: false, prependCompanyNameIntoTitle: true },
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: { title: 'Privacy Policy', isImage: false },
  },
  {
    path: 'website-terms-conditions',
    component: WebsiteTermsConditionsComponent,
    data: { title: 'Website Terms of Use', isImage: false },
  },
  {
    path: 'service-terms-conditions',
    component: ServiceTermsConditionsComponent,
    data: { title: 'ECF Issuer and Investor Terms and Conditions', isImage: false },
  },
  {
    path: 'pstx-terms-conditions',
    component: PstxTermsConditionsComponent,
    data: { title: 'PSTX Terms and Conditions', isImage: false },
  },
  {
    path: 'ieo-terms-conditions',
    component: IeoTermsConditionsComponent,
    data: { title: 'TCF Issuer and Investor Terms and Conditions', isImage: false },
  },
  {
    path: 'nominee-agreement',
    component: NomineeAgreementComponent,
    data: { title: 'Nominee Agreement', isImage: false },
  },
  {
    path: 'pdpa-policy',
    component: PersonalDataProtectionComponent,
    data: { title: 'Personal Data Protection Act Policy' },
  },
  {
    path: 'ImageNotFound',
    component: NotFoundPageComponent,
    data: { title: 'Image Not Found', isImage: true },
  },
  {
    path: 'partner/beyond4',
    component: BeyondFourComponent,
    data: { title: 'BEYOND4 Accelerator Startups' },
  },
  {
    path: 'partner/mranti',
    component: MrantiComponent,
    data: { title: 'MRANTI startups and investment opportunities' },
  },
  {
    path: 'partner/broku',
    component: BrokuComponent,
    data: { title: 'Broku Supported F&B Startups' },
  },
  {
    path: 'partner/women-in-tech',
    component: WomenInTechComponent,
    data: { title: 'Women in Tech' },
  },
  {
    path: 'partner/um-innovations',
    component: UmInnovationsComponent,
    data: { title: 'UM Innovations' },
  },
  {
    path: 'partner/zfounder',
    component: ZFounderComponent,
    data: { title: 'ZFounder' },
  },
  {
    path: 'partner/watchtowerfriends',
    component: WatchTowerAndFriendsComponent,
    data: { title: 'WatchTower & Friends' },
  },
  {
    path: 'proceed-e-kyc',
    component: ProceedEKycComponent,
    data: { title: 'Proceed eVerification', isProfile: true },
    canActivate: [AuthGuard],
  },
  { path: 'learn', component: LearnComponent, data: { title: 'Learning Portal' } },
  { path: 'learn/:group/:slug', component: LearnArticleComponent },
  {
    path: 'style-guide',
    component: StyleGuideComponent,
    data: { title: 'pitchIN CSS Library' },
    canActivate: [InternalGuard],
  },
  {
    path: 'news/raya-with-pitchin-2023',
    component: RayaWithPitchinComponent,
    data: { title: 'Raya with pitchIN' },
  },
  {
    path: '',
    component: InvestorDashboardComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./layouts/investor-dashboard/investor-dashboard.module').then(
            (m) => m.InvestorDashboardModule,
          ),
      },
    ],
  },
  {
    path: '',
    component: MysecLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./layouts/mysec-layout/mysec-layout.module').then((m) => m.MysecLayoutModule),
      },
    ],
  },
  {
    path: '',
    component: ProfileComponent,
    children: [
      {
        path: 'profile',
        loadChildren: () => import('./layouts/profile/profile.module').then((m) => m.ProfileModule),
      },
    ],
  },
  {
    path: 'my-campaign',
    component: MyCampaignComponent,
    data: { title: 'My Campaign', roles: [Role.IssuerRep] },
    canActivate: [AuthGuard],
  },
  {
    path: 'my-campaign/campaign-draft-reject/:fundRaiseApplicationId/:campaignId',
    component: CampaignDraftRejectComponent,
    data: { title: 'Campaign Draft', roles: [Role.IssuerRep] },
    canActivate: [AuthGuard],
  },
  {
    path: 'my-campaign/edit-campaign/:fundRaiseApplicationId/campaign/:campaignId',
    component: CampaignFormComponent,
    data: {
      title: 'Edit Campaign',
      type: CampaignFormComponentType.NewDraft,
      roles: [Role.IssuerRep],
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'my-campaign/edit-campaign/campaign/:campaignId',
    component: CampaignFormComponent,
    data: {
      title: 'Edit Campaign',
      type: CampaignFormComponentType.EditDraft,
      roles: [Role.IssuerRep],
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'my-campaign/campaign-draft/:campaignId',
    component: CampaignDraftComponent,
    data: { title: 'Campaign Draft', roles: [Role.IssuerRep] },
    canActivate: [AuthGuard],
  },
  {
    path: 'my-campaign/investor-list/:slug',
    component: CampaignInvestorListComponent,
    data: { title: 'Campaign Investor List', roles: [Role.IssuerRep] },
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard/portfolio',
    component: MyPortfolioComponent,
    data: {
      title: 'Equity Portfolio',
      data: 'my-portfolio',
      roles: [Role.IndividualInvestor, Role.CompanyRep],
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'redirect-login',
    component: LoginBehalfRedirectionComponent,
    data: { title: 'Redirecting...' },
  },
  {
    path: 'postfundraising/shareholders',
    component: MysecSettingsComponent,
    data: { title: 'Shareholders Dashboard', roles: [Role.IssuerRep] },
    canActivate: [AuthGuard],
  },
  {
    path: 'postfundraising/shareholders/team-member/add',
    component: AddTeamMemberComponent,
    data: { title: 'Add Member', roles: [Role.IssuerRep] },
    canActivate: [AuthGuard],
  },
  {
    path: 'postfundraising/shareholders/team-member/edit/:mySecCompanyTeamId',
    component: AddTeamMemberComponent,
    data: { title: 'Edit Member', roles: [Role.IssuerRep] },
    canActivate: [AuthGuard],
  },
  {
    path: 'profile/settings/security/enable-2fa',
    component: EnableTwoFactorAuthComponent,
    data: { title: '2-Factor Authentication', roles: [Role.IndividualInvestor, Role.CompanyRep] },
    canActivate: [AuthGuard],
  },
  {
    path: 'profile/settings/security/backup-codes',
    component: BackupCodesComponent,
    data: { title: 'Backup Codes', roles: [Role.IndividualInvestor, Role.CompanyRep] },
    canActivate: [AuthGuard],
  },
  {
    path: 'profile/settings/security/remove-2fa',
    component: RemoveTwoFactorAuthComponent,
    data: { title: 'Remove 2FA', roles: [Role.IndividualInvestor, Role.CompanyRep] },
    canActivate: [AuthGuard],
  },
  {
    path: 'partner',
    component: PartnerComponent,
    data: { title: 'Explore partnered investments' },
  },
  {
    path: 'shariah-compliant-investment',
    component: ShariahCompliantInvestmentComponent,
    data: {
      title:
        'Shariah-compliant Investing with pitchIN | Ethical Crowdfunding & Investment Opportunities',
    },
  },
  {
    path: 'brand-guidelines',
    component: BrandGuidelinesComponent,
    data: {
      title: 'Brand guidelines',
    },
  },
  {
    path: '**',
    component: NotFoundPageComponent,
    data: { title: '404 Page Not Found', prependCompanyNameIntoTitle: true },
  },
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 100],
  onSameUrlNavigation: 'reload',
};

@NgModule({
  imports: [CommonModule, BrowserModule, RouterModule.forRoot(routes, routerOptions)],
  exports: [],
})
export class AppRoutingModule {}
