import { InvestorShareMarketStatusFilter } from '../../enum/investor-share-market-status.enum';
import { ShareMarketStatus } from '../../enum/mysec/share-market-status.enum';
import { ShareStatus } from '../../enum/mysec/share-status.enum';
import { Announcement } from '../campaign/announcements';
import { Campaign } from '../campaign/campaign';
import { Sector } from '../company/sector';
import { SocialLink } from '../company/social-link';
import { BusinessModel } from './business.model';
import { RelatedShare } from './related-share';
import { ShareHolder } from './share-holder.model';

export class Business {
  tickerCode: string;
  companyId: number;
  companyName: string;
  companyLogo: string;
  lastTrade: number;
  bestBid: number;
  bidQty: number;
  bestOffer: number;
  offerQty: number;
  priceChg: number;
  tagline: string;
  slug: string;
  registrationNo: string;
  address: string;
  companyBannerUrl: string;
  companyLogoUrl: string;
  companySectors: Sector;
  companySocialLinks: SocialLink;
  companyAnnouncements: Announcement[];
  name: string;
  registrationNumber: string;
  websiteUrl: string;
  valuation: number;
  valuationBasis: string;
  brandName: string;
  relatedShares?: RelatedShare[];
  orderQuantity: string;
  changesValue: number;
  investorShareMarketStatus: InvestorShareMarketStatusFilter[];
  isWatchList: boolean;
  status: ShareMarketStatus;
  mySecShareStatus: ShareStatus;
  shareHolder: ShareHolder;
  tradeVolumeSinceFirstTraded: number;
  latestCampaign: Campaign;

  constructor(model: BusinessModel) {
    this.tickerCode = model.share?.tickerCode;
    this.companyId = model.id || model.company?.id || model.company?.companyId;
    this.companyLogo = model.companyLogoUrl || model.company?.companyLogoUrl;
    this.companyName = model.name || model.company?.name;
    this.companyAnnouncements = model.companyAnnouncements;
    this.lastTrade = model.shareMarket?.lastDoneTransactionPrice;
    this.bestBid = model.shareMarket?.bestBidPrice;
    this.bidQty = model.shareMarket?.bestBidQuantity;
    this.bestOffer = model.shareMarket?.bestAskPrice;
    this.offerQty = model.shareMarket?.bestAskQuantity;
    this.priceChg = model.shareMarket?.changesPercentage;
    this.changesValue = model.shareMarket?.changesValue;
    this.tagline = model.tagline || model.share?.tagline;
    this.slug = model.slug || model.share?.slug;
    this.registrationNo = model.share?.registrationNumber;
    this.address = model.address;
    this.companyBannerUrl = model.companyBannerUrl;
    this.companySectors = model.companySectors;
    this.companySocialLinks = model.companySocialLinks;
    this.registrationNumber = model.registrationNumber;
    this.websiteUrl = model.websiteUrl;
    this.valuation = model.valuation;
    this.valuationBasis = model.valuationBasis;
    this.brandName = model.brandName || model.company?.brandName;
    this.orderQuantity = model.orderQuantity;
    this.relatedShares = model.relatedShares || [];
    this.investorShareMarketStatus = [];
    this.isWatchList = model.isWatchList;
    this.status = model.status || model.shareMarket?.status;
    this.mySecShareStatus = model?.share?.mySecShareStatus;
    this.shareHolder = model.shareHolder;
    this.tradeVolumeSinceFirstTraded = model.tradeVolumeSinceFirstTraded;
    this.latestCampaign = model.latestCampaign;

    if (model.watched) this.investorShareMarketStatus.push(InvestorShareMarketStatusFilter.Watched);

    if (model.ownShares)
      this.investorShareMarketStatus.push(InvestorShareMarketStatusFilter.OwnShares);

    if (model.haveOrders)
      this.investorShareMarketStatus.push(InvestorShareMarketStatusFilter.HaveOrders);
  }
}
