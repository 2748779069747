import { Component, Input, OnChanges } from '@angular/core';
import { Sector } from '../../../core/models/company/sector';
import {
  CampaignImpact,
  Impact,
  PartnershipBacked,
} from '../../../core/enum/campaign-categorisation.enum';

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss'],
})
export class TagListComponent implements OnChanges {
  @Input() tags: Sector[] = [];
  @Input() max: number;
  @Input() excludes: string[] = [];
  @Input() partnershipBacked: PartnershipBacked;
  @Input() campaignImpacts: CampaignImpact[] = [];

  PartnershipBacked = PartnershipBacked;
  Impact = Impact;

  constructor() {}

  ngOnChanges() {
    if (this.excludes.length > 0) {
      this.tags = this.tags?.filter((tag) => !this.excludes.includes(tag?.name));
    }
  }
}
