// reference: https://prismic.io/docs/rest-api-technical-reference
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { LearnGroup } from '../../enum/learn-group.enum';
import { Article } from '../../models/learn/article';
import { SurveyFormFields } from '../../models/survey-form/form-fields';

@Injectable()
export class PrismicService {
  private articles = Object.keys(LearnGroup).reduce((acc, key) => ({ ...acc, [key]: null }), {});
  private subMenus = Object.keys(LearnGroup).reduce((acc, key) => ({ ...acc, [key]: null }), {});
  private tableOfContents: object;

  private route = environment.apiServer + 'Api/v1/StaticUI/public';
  private prismicUrl = 'https://pitchin-my.cdn.prismic.io/api/v2/';

  constructor(private http: HttpClient) {}

  /**
   * Fetches the current Prismic API reference
   * @returns Observable with the Prismic reference string
   */
  getPrismicRef(): Observable<string> {
    return this.http.get<{ refs: { id: string; ref: string }[] }>(`${this.prismicUrl}`).pipe(
      map((response) => {
        const ref = response.refs[0]?.ref;
        if (!ref) {
          throw new Error('No ref found in Prismic API response');
        }
        return ref;
      }),
      catchError((error) => {
        console.error('Error fetching ref:', error);
        throw error;
      }),
    );
  }

  private getEnumKey(enumValue) {
    return Object.keys(LearnGroup).find((k) => LearnGroup[k] == enumValue);
  }

  getHomepage(): Observable<object> {
    const params = { noToken: 'noToken' };

    if (this.tableOfContents) {
      return of(this.tableOfContents);
    }

    return this.http.get(this.route + '/homepage', { params: params }).pipe(
      map((data: any) => {
        this.tableOfContents = data.result;

        return data.result;
      }),
    );
  }

  getSubmenu(group: LearnGroup): Observable<Article[]> {
    const params = { noToken: 'noToken', group: group };
    const key = this.getEnumKey(group);

    if (key && this.subMenus[key]) return of(this.subMenus[key]);

    return this.http.get(this.route + '/submenu', { params: params }).pipe(
      map((data: any) => {
        this.subMenus[key] = data.result;

        return data.result;
      }),
    );
  }

  getArticle(slug: string, group?: LearnGroup): Observable<Article> {
    const params = { noToken: 'noToken', group: group };

    return this.http.get(this.route + '/article/' + slug, { params: params }).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  sortArticle(list: Article[]) {
    const customSort = (a, b) => {
      if (a.data.position == null) return 1;
      if (b.data.position == null) return -1;
      return a.data.position - b.data.position;
    };

    return list.sort(customSort);
  }

  getFrontPageStats(): Observable<any> {
    return this.getPrismicRef().pipe(
      // Fetch the `front_page_statistics` document using the latest ref
      switchMap((ref) => {
        const params = {
          noToken: 'noToken',
          q: '[[at(document.type, "front_page_statistics")]]',
          pageSize: 1,
          ref,
        };

        return this.http.get(this.prismicUrl + 'documents/search', { params }).pipe(
          map((data: any) => {
            return data.results[0].data;
          }),
        );
      }),
    );
  }

  getCancelInvestmentSurvey(): Observable<SurveyFormFields> {
    return this.getPrismicRef().pipe(
      switchMap((ref) => {
        const params = {
          noToken: 'noToken',
          q: '[[at(document.id, "Z9J4RxAAACIAftlB")]]',
          pageSize: 1,
          ref,
        };

        return this.http.get(this.prismicUrl + 'documents/search', { params }).pipe(
          map((data: any) => {
            return data.results[0].data;
          }),
        );
      }),
    );
  }
}
