import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { WindowReferenceService } from './window-reference.service';
import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class PostMessageService {
  private readonly window;
  private readonly messageListener: (event: MessageEvent) => void;

  constructor(
    private readonly _windowRef: WindowReferenceService,
    @Inject(PLATFORM_ID) private readonly platform: Object,
  ) {
    this.window = _windowRef.nativeWindow;
    this.messageListener = this.createMessageListener();
  }

  private createMessageListener() {
    return (event) => {
      if (event.origin.includes(environment.ieoBaseUrl)) return;

      if (event.data === 'requestLocalStorage') {
        event.source.postMessage(
          {
            type: 'localStorage',
            data: { ...localStorage },
          },
          event.origin,
        );
      }
    };
  }

  private postMessage(message: string | object, eventData?: string) {
    if (!isPlatformBrowser(this.platform)) return;

    this.window.addEventListener('message', this.messageListener);
  }

  syncLocalStorage() {
    console.log('SDK auth - attempt to sync localStorage');
    this.postMessage(
      {
        type: 'localStorage',
        data: { ...localStorage },
      },
      'requestLocalStorage',
    );
  }

  cleanUp() {
    if (isPlatformBrowser(this.platform)) {
      this.window.removeEventListener('message', this.messageListener);
    }
  }
}
