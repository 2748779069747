import { Component, OnInit } from '@angular/core';
import { SurveyFormFields } from '../../core/models/survey-form/form-fields';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-survey-form',
  templateUrl: './survey-form.component.html',
  styleUrls: ['./survey-form.component.scss'],
})
export class SurveyFormComponent implements OnInit {
  form: UntypedFormGroup;
  showFormError: boolean = false;
  surveyFormFields: SurveyFormFields;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly modal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = this.formBuilder.group({
      reason: ['', Validators.required],
      otherReason: [''],
    });
  }

  handleValueChange(value: string) {
    if (value !== 'Other') {
      this.form.get('otherReason').setValue('');
    }
  }

  close() {
    this.modal.dismiss();
  }

  submit() {
    this.showFormError = false;

    if (this.form.invalid) {
      this.showFormError = true;
      return;
    }

    this.modal.close({
      reason: this.form.get('reason').value,
      otherReason: this.form.get('otherReason').value,
    });
  }
}
