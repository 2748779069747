<div class="position-relative">
  <input
    type="text"
    class="form-control"
    placeholder="{{ placeholder | translate }}"
    #searchInput
    [class.disabled]="max && selectedList && selectedList.length >= max"
    [formControl]="searchCtrl"
    (focus)="onfocus()"
    (blur)="onblur()"
  />
  <div class="input-desc tw-text-[0.9rem] tw-italic" *ngIf="max">
    {{ 'company.enterMaxSector' | translate: { max: max } }}
  </div>
  <div class="sector-list bg-fill" *ngIf="focus && isLoading">
    <agmo-loading></agmo-loading>
  </div>
  <ng-container *ngIf="focus && !isLoading && list">
    <div class="sector-list">
      <ul class="list-group list-group-flush">
        <ng-container *ngIf="popularSectors && popularSectors.length > 0">
          <li class="list-group-title">{{ 'common.popularSectors' | translate }}</li>
          <li class="list-group-item" *ngFor="let item of popularSectors" (click)="addItem(item)">
            {{ item.name }}
          </li>
        </ng-container>
        <ng-container *ngIf="commonSectors && commonSectors.length > 0">
          <li class="list-group-title">{{ 'common.others' | translate }}</li>
          <li class="list-group-item" *ngFor="let item of commonSectors" (click)="addItem(item)">
            {{ item.name }}
          </li>
        </ng-container>
      </ul>
    </div>
    <div
      class="sector-list"
      *ngIf="list && !list.popularSectors && list.commonSectors?.length == 0 && searchCtrl.value"
    >
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          {{ 'common.noRecord' | translate }}
        </li>
      </ul>
    </div>
  </ng-container>
  <div class="mt-2" *ngIf="selectedList && selectedList.length > 0">
    <div class="text-black font-weight-bold sector-list-label" *ngIf="sectorListLabel">
      {{ sectorListLabel | translate }}
    </div>
    <div class="block-badge-wrap" *ngFor="let item of selectedList">
      <div class="d-flex flex-row block-badge mr-3">
        <div class="font-weight-bold mr-2">{{ item.name }}</div>
        <div class="mr-2">|</div>
        <div class="align-self-center pointer" (click)="deleteItem(item.id)">
          <i class="fa fa-times" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  </div>
</div>
