<app-survey-form (formSubmit)="handleSubmit($event)"></app-survey-form>

<div class="container-fluid tw-py-8">
  <section class="sg-section">
    <div class="tw-border tw-border-divider tw-p-10">
      <h1 class="p-text-h1 tw-mb-8">
        Main header <code>.p-text-h1</code>

        <span class="p-text-sub1 tw-block">Sub header description<code>.p-text-sub1</code></span>
      </h1>

      <p class="p-text-p">
        Invest in Malaysia’s most promising and innovative projects and businesses. pitchIN
        Launchpad looks to pioneer Malaysia’s regulated digital asset fundraising in the blockchain
        space.<code>.p-text-p</code>
      </p>
    </div>
  </section>

  <section class="sg-section">
    <div class="sg-title">Headings <span class="tw-opacity-50">(First letter caps)</span></div>
    <h1 class="p-text-h1">pitchIN is Awesome<code>.p-text-h1</code></h1>
    <h2 class="p-text-h2">pitchIN is Awesome<code>.p-text-h2</code></h2>
    <h3 class="p-text-h3">pitchIN is Awesome<code>.p-text-h3</code></h3>
  </section>

  <section class="sg-section">
    <div class="row">
      <div class="col-md-6 tw-mb-5 md:tw-mb-0">
        <div class="sg-title">Sub-headings</div>
        <h1 class="tw-leading-none">
          <span class="p-text-sub1">Larger text #1</span>
          <code>.p-text-sub1</code>
        </h1>
        <h1 class="tw-leading-none">
          <span class="p-text-sub2">Larger text #2</span>
          <code>.p-text-sub2</code>
        </h1>
        <h1 class="tw-leading-none">
          <span class="p-text-emphasis">Emphasis & deck #1</span>
          <code>.p-text-emphasis</code>
        </h1>
      </div>

      <div class="col-md-6">
        <div class="sg-title">Body text</div>
        <div class="tw-max-w-md">
          <p class="p-text-p">
            We make it possible for everyone to take part in funding the next big thing, with
            potential to earn a positive return.<code>.p-text-p</code>
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="sg-section">
    <div class="row">
      <div class="col-md-6">
        <div class="sg-title">
          Highlights or Preheader <span class="tw-opacity-50">(color: any)</span>
        </div>

        <div class="tw-space-y-3">
          <div class="p-text-preheader">For Highlights<code>.p-text-preheader</code></div>
          <div class="p-text-preheader tw-text-info">Preheaders<code>.p-text-preheader</code></div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="sg-title">Tags / Badges <span class="tw-opacity-50">(color: any)</span></div>
        <div class="p-text-tag tw-mb-2.5">
          Manufacturing
          <code>.p-text-tag</code>
        </div>
        <div>
          <span class="p-text-tag p-text-tag--bg">E-Commerce</span>
          <code>.p-text-tag.p-text-tag--bg</code>
        </div>
        <div>
          <span class="p-text-tag--round p-tag--error">Sell</span>
          <code>.p-text-tag--round.p-tag--error</code>
        </div>
        <div>
          <span class="p-text-tag--round p-tag--success">Buy</span>
          <code>.p-text-tag--round.p-tag--success</code>
        </div>
        <div>
          <span class="p-text-tag--round p-tag--warning">Nominee</span>
          <code>.p-text-tag--round.p-tag--warning</code>
        </div>
        <div>
          <span class="p-text-tag--round p-tag--info">Direct</span>
          <code>.p-text-tag--round.p-tag--info</code>
        </div>
        <div>
          <span class="p-text-tag--round p-tag--pink">Market</span>
          <code>.p-text-tag--round.p-tag--pink</code>
        </div>
        <div>
          <span class="p-text-tag--round p-tag--purple">Limit</span>
          <code>.p-text-tag--round.p-tag--purple</code>
        </div>
        <div>
          <span class="p-text-tag--round p-text-tag--dot">Default</span>
          <code>.p-text-tag--round.p-text-tag--dot</code>
        </div>
        <div>
          <span class="p-text-tag--round p-text-tag--dot p-tag--success">Order Opened</span>
          <code>.p-text-tag--round.p-text-tag--dot.p-tag--success</code>
        </div>
        <div>
          <span class="p-text-tag--round p-text-tag--dot p-tag--orange">Partially Executed</span>
          <code>.p-text-tag--round.p-text-tag--dot.p-tag--orange</code>
        </div>
      </div>

      <div class="col-md-6 tw-mt-10">
        <div class="sg-title">Tooltip</div>
        <div class="p-text-tooltip tw-inline-block" placement="top" ngbTooltip="Tooltip on top">
          Hover to show Tooltip
        </div>
        <code>.p-text-tooltip</code>
      </div>
    </div>
  </section>

  <section class="sg-section">
    <div class="row">
      <div class="col-md-6">
        <div class="sg-title">Buttons</div>

        <div class="tw-space-y-3">
          <div>
            <button class="btn btn-primary">Primary</button>
            <code>.btn.btn-primary</code>
          </div>
          <div>
            <button class="btn btn-green">Secondary</button>
            <code>.btn.btn-green</code>
          </div>
          <div>
            <button class="btn btn-grey">Grey</button>
            <code>.btn.btn-grey</code>
          </div>
          <div>
            <button class="btn btn-primary-border">Link</button>
            <code>.btn.btn-primary-border</code>
          </div>
          <div>
            <button class="btn btn-primary btn-sm">Smaller size</button>
            <code>.btn.btn-primary.btn-sm</code>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="sg-title">Links</div>

        <div class="tw-space-y-3">
          <div>
            <a routerLink="/style-guide">This is <strong>default</strong> link</a
            ><code>(no class)</code>
          </div>
          <div>
            <a routerLink="/style-guide" class="p-link-underline"
              >This is <strong>default</strong> link</a
            ><code>.p-link-underline</code>
          </div>
          <div>
            <a routerLink="/style-guide" class="p-link-primary p-link-underline"
              >This is <strong>black</strong> link</a
            ><code>.p-link-primary.p-link-underline</code>
          </div>
          <div>
            <a routerLink="/style-guide" class="p-link-secondary"
              >This is <strong>blue</strong> link</a
            ><code>.p-link-secondary</code>
          </div>
          <div>
            <a routerLink="/style-guide" class="p-link-secondary p-link-underline"
              >This is <strong>blue</strong> link</a
            ><code>.p-link-secondary.p-link-underline</code>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="sg-section">
    <div class="row">
      <div class="col-md-6">
        <div class="sg-title">Alert Bar</div>

        <div class="tw-space-y-3">
          <div>
            <code>[type="success"]</code>
            <app-alert-bar type="success"> This is <strong>Success</strong> color </app-alert-bar>
          </div>

          <div>
            <code>[type="error"]</code>
            <app-alert-bar type="error"> This is <strong>Error</strong> color </app-alert-bar>
          </div>

          <div>
            <code>[type="warning"]</code>
            <app-alert-bar type="warning"> This is <strong>Warning</strong> color </app-alert-bar>
          </div>

          <div>
            <code>[type="info"]</code>
            <app-alert-bar type="info"> This is <strong>Info</strong> color </app-alert-bar>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="sg-title">Toast</div>

        <button (click)="openToast('success', true)">Show `Successful`</button><br />
        <button (click)="openToast('error', true)">Show `Error`</button><br />
        <button (click)="openToast('info')">Show `Info`</button><br />
        <button (click)="openToast('warning')">Show `Warning`</button>
      </div>
    </div>
  </section>
</div>
