<main class="tw-pb-12">
  <section
    class="tw-bg-[url(src/assets/img/banner/brand-guidelines-header-mobile.svg)] tw-bg-cover tw-bg-top tw-bg-no-repeat lg:tw-bg-[url(src/assets/img/banner/brand-guidelines-header.svg)] lg:tw-bg-right lg:tw-py-12"
  >
    <div class="container-fluid tw-flex tw-flex-col tw-gap-4 tw-p-8">
      <img src="assets/img/brand/pitchin_logo_white.svg" alt="pitchIN logo" class="tw-w-20" />
      <h1 class="tw-m-0 tw-text-4xl tw-font-semibold tw-text-white">Brand Guidelines</h1>
      <p class="tw-m-0 tw-text-sm tw-text-white md:tw-max-w-xl">
        Welcome to the pitchIN Brand Resource Center. Please follow the guidelines on this site when
        referring to pitchIN as a fundraising platform or partner.
      </p>
      <a
        href="assets/doc/Media_Kit.zip"
        download
        class="tw-mt-4 tw-w-fit tw-rounded tw-bg-primary-ligther tw-p-4 tw-text-sm tw-font-semibold tw-tracking-wide tw-text-white hover:tw-text-white hover:tw-opacity-90"
      >
        Download Kit
      </a>
    </div>
  </section>
  <div class="container-fluid tw-flex tw-flex-col tw-gap-8">
    <section class="tw-flex tw-flex-col tw-gap-4 tw-p-4">
      <h2 class="tw-text-xl tw-font-semibold">pitchIN Logo</h2>

      <div class="lg:tw-grid lg:tw-grid-cols-3 lg:tw-gap-4">
        <article class="tw-flex tw-flex-col">
          <div
            class="tw-mb-4 tw-flex tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-gray-300 tw-bg-gray-100 tw-p-12 lg:tw-h-56"
          >
            <img
              src="assets/img/brand/pitchin_logo.svg"
              alt="pitchIN logo"
              class="tw-w-3/6 lg:tw-w-4/6"
            />
          </div>
          <h3 class="tw-m-0 tw-text-sm tw-font-bold tw-tracking-wide">Primary logo</h3>
          <p class="tw-text-sm">Our primary logo consists of "pitchIN" in a 'wallet'.</p>
        </article>
        <article class="tw-flex tw-flex-col">
          <div
            class="tw-mb-4 tw-flex tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-gray-300 tw-bg-gray-100 tw-p-12 lg:tw-h-56"
          >
            <img
              src="assets/img/brand/pitchin_logo_square.svg"
              alt="pitchIN logo"
              class="tw-w-2/6"
            />
          </div>
          <h3 class="tw-m-0 tw-text-sm tw-font-bold tw-tracking-wide">Secondary logo</h3>
          <p class="tw-text-sm">
            Our secondary logo consists of “P” in a “wallet”. When the primary logo doesn’t fit in
            the tight space, use this logo instead.
          </p>
        </article>
      </div>
    </section>

    <section class="tw-flex tw-flex-col tw-gap-4 tw-p-4">
      <h2 class="tw-text-xl tw-font-semibold">pitchIN Primary Colors</h2>
      <div class="tw-flex tw-flex-col tw-gap-8 lg:tw-grid lg:tw-grid-cols-3 lg:tw-gap-4">
        <article class="tw-flex tw-flex-col">
          <div
            class="tw-mb-4 tw-flex tw-h-44 tw-w-full tw-items-center tw-justify-center tw-rounded-md tw-bg-primary lg:tw-h-56"
          ></div>
          <h3 class="tw-text-sm tw-font-bold tw-tracking-wide">Red</h3>
          <div class="tw-grid tw-grid-cols-8 tw-gap-y-1">
            <p class="tw-col-span-1 tw-m-0 tw-text-xs tw-uppercase tw-text-gray-800">cmyk</p>
            <p class="tw-col-span-7 tw-m-0 tw-text-xs tw-proportional-nums tw-text-gray-800">
              0, 83, 80, 25
            </p>
            <p class="tw-col-span-1 tw-m-0 tw-text-xs tw-uppercase tw-text-gray-800">rgb</p>
            <p class="tw-col-span-7 tw-m-0 tw-text-xs tw-proportional-nums tw-text-gray-800">
              191, 32, 38
            </p>
            <p class="tw-col-span-1 tw-m-0 tw-text-xs tw-uppercase tw-text-gray-800">hex</p>
            <p class="tw-col-span-7 tw-m-0 tw-text-xs tw-proportional-nums tw-text-gray-800">
              BF2026
            </p>
          </div>
        </article>

        <article class="tw-flex tw-flex-col">
          <div
            class="tw-mb-4 tw-flex tw-h-44 tw-w-full tw-items-center tw-justify-center tw-rounded-md tw-bg-secondary lg:tw-h-56"
          ></div>
          <h3 class="tw-text-sm tw-font-bold tw-tracking-wide">Green</h3>
          <div class="tw-grid tw-grid-cols-8 tw-gap-y-1">
            <p class="tw-col-span-1 tw-m-0 tw-text-xs tw-uppercase tw-text-gray-800">cmyk</p>
            <p class="tw-col-span-7 tw-m-0 tw-text-xs tw-proportional-nums tw-text-gray-800">
              100, 0, 48, 59
            </p>
            <p class="tw-col-span-1 tw-m-0 tw-text-xs tw-uppercase tw-text-gray-800">rgb</p>
            <p class="tw-col-span-7 tw-m-0 tw-text-xs tw-proportional-nums tw-text-gray-800">
              0, 105, 55
            </p>
            <p class="tw-col-span-1 tw-m-0 tw-text-xs tw-uppercase tw-text-gray-800">hex</p>
            <p class="tw-col-span-7 tw-m-0 tw-text-xs tw-proportional-nums tw-text-gray-800">
              006937
            </p>
          </div>
        </article>
      </div>
    </section>

    <section class="tw-flex tw-flex-col tw-gap-4 tw-p-4">
      <div>
        <h2 class="tw-mb-2 tw-text-xl tw-font-semibold">Clear Space</h2>
        <p class="tw-text-sm lg:tw-max-w-3xl">
          Clear space prevents type, imagery or other graphic elements from interfering with the
          legibility of our logo. <br />
          No graphic elements should encroach the border around the logomark. <br />This space is
          determined by 50% height of logomark on each side.
        </p>
      </div>

      <div class="lg:tw-grid lg:tw-grid-cols-3 lg:tw-gap-4">
        <div
          class="tw-mb-4 tw-flex tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-gray-300 tw-bg-gray-100 tw-p-8 lg:tw-h-56"
        >
          <img
            src="assets/img/brand/guidelines/clear-space.svg"
            alt="pitchIN logo"
            class="tw-w-5/6"
          />
        </div>

        <div
          class="tw-mb-4 tw-flex tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-gray-300 tw-bg-gray-100 tw-p-8 lg:tw-h-56"
        >
          <img
            src="assets/img/brand/guidelines/clear-space-square.svg"
            alt="pitchIN logo"
            class="tw-w-3/6 lg:tw-w-2/6"
          />
        </div>
      </div>
    </section>

    <section class="tw-flex tw-flex-col tw-gap-4 tw-p-4">
      <h2 class="tw-mb-2 tw-text-xl tw-font-semibold">When using pitchIN logo</h2>

      <div class="lg:tw-grid lg:tw-grid-cols-3 lg:tw-gap-4">
        <article>
          <div
            class="tw-mb-4 tw-flex tw-items-center tw-justify-center tw-rounded-md tw-border tw-bg-secondary tw-p-4"
          >
            <img
              src="assets/img/brand/pitchin_logo_white.svg"
              alt="pitchIN logo"
              class="tw-w-2/6"
            />
          </div>
          <div
            class="tw-mb-4 tw-flex tw-items-center tw-justify-center tw-rounded-md tw-border tw-bg-black tw-p-4"
          >
            <img
              src="assets/img/brand/pitchin_logo_white.svg"
              alt="pitchIN logo"
              class="tw-w-2/6"
            />
          </div>
          <p class="tw-text-sm">
            Use white logo on coloured backgrounds that clashed with our red and green colour, or
            darker coloured background.
          </p>
        </article>
        <article>
          <div
            class="tw-mb-4 tw-flex tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-gray-300 tw-bg-white tw-p-4"
          >
            <img src="assets/img/brand/pitchin_logo.svg" alt="pitchIN logo" class="tw-w-2/6" />
          </div>
          <div
            class="tw-mb-4 tw-flex tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-gray-300 tw-bg-[#FEE2E3] tw-p-4"
          >
            <img src="assets/img/brand/pitchin_logo.svg" alt="pitchIN logo" class="tw-w-2/6" />
          </div>
          <p class="tw-text-sm">Use primary logo on vibrant or white coloured background.</p>
        </article>
      </div>
    </section>

    <section class="tw-flex tw-flex-col tw-gap-4 tw-p-4">
      <h2 class="tw-mb-2 tw-text-xl tw-font-semibold">When referring to pitchIN</h2>
      <article class="lg:tw-grid lg:tw-grid-cols-3 lg:tw-gap-4">
        <div
          class="tw-mb-4 tw-flex tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-gray-300 tw-bg-gray-100 tw-p-12 lg:tw-h-56"
        >
          <img src="assets/img/brand/pitchin_logo_text.svg" alt="pitchIN logo" class="tw-w-5/6" />
        </div>
        <ul class="tw-pl-5 tw-text-sm tw-text-gray-800">
          <li>pitchIN is always spelled with lowercase p and uppercase I and N.</li>
          <li>
            When promoting a fundraising campaign, refer to pitchIN as an equity or token
            crowdfunding platform and use phrases like "investment available on pitchIN" or similar
            expressions.
          </li>
          <li>
            If you're a partner, refer to pitchIN as an ECF/TCF partner and use phrases like "in
            partnership with pitchIN" or similar wording.
          </li>
        </ul>
      </article>
    </section>

    <section class="tw-flex tw-flex-col tw-gap-4 tw-p-4">
      <h2 class="tw-mb-2 tw-text-xl tw-font-semibold">PSTX Logo</h2>

      <article class="lg:tw-grid lg:tw-grid-cols-3 lg:tw-gap-4">
        <div
          class="tw-mb-4 tw-flex tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-gray-300 tw-bg-gray-100 tw-p-12 lg:tw-h-56"
        >
          <img src="assets/img/logo/logo_pstx.png" alt="PSTX logo" class="tw-w-5/6" />
        </div>
        <div>
          <h4 class="tw-mb-2 tw-text-sm tw-font-semibold tw-tracking-wide">Primary Logo</h4>
          <ul class="tw-pl-5 tw-text-sm tw-text-gray-800">
            <li>The PSTX logo represents the pitchIN Secondary Trading Market.</li>
            <li>
              It features "PST" in green (#006937) and "X" in red (#BF2026), creating a bold and
              distinctive design.
            </li>
          </ul>
        </div>
      </article>
    </section>

    <section class="tw-flex tw-flex-col tw-gap-4 tw-p-4">
      <h2 class="tw-mb-2 tw-text-xl tw-font-semibold">When using PSTX logo</h2>
      <div class="lg:tw-grid lg:tw-grid-cols-3 lg:tw-gap-4">
        <article>
          <div
            class="tw-mb-4 tw-flex tw-items-center tw-justify-center tw-rounded-md tw-border tw-bg-secondary tw-p-4"
          >
            <img src="assets/img/logo/logo_pstx_white.png" alt="PSTX logo" class="tw-w-3/6" />
          </div>
          <div
            class="tw-mb-4 tw-flex tw-items-center tw-justify-center tw-rounded-md tw-border tw-bg-black tw-p-4"
          >
            <img src="assets/img/logo/logo_pstx_white.png" alt="PSTX logo" class="tw-w-3/6" />
          </div>
          <p class="tw-text-sm">
            Use white logo on coloured background that clashed with our red and green colour, or
            darker coloured background.
          </p>
        </article>
        <article>
          <div
            class="tw-mb-4 tw-flex tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-gray-300 tw-bg-white tw-p-4"
          >
            <img src="assets/img/logo/logo_pstx.png" alt="PSTX logo" class="tw-w-3/6" />
          </div>
          <div
            class="tw-mb-4 tw-flex tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-gray-300 tw-bg-[#DCF4E9] tw-p-4"
          >
            <img src="assets/img/logo/logo_pstx.png" alt="PSTX logo" class="tw-w-3/6" />
          </div>
          <p class="tw-text-sm">Use primary logo on vibrant or white coloured background.</p>
        </article>
      </div>
    </section>

    <section class="tw-flex tw-flex-col tw-gap-4 tw-p-4">
      <h2 class="tw-mb-2 tw-text-xl tw-font-semibold">When referring to PSTX</h2>

      <div class="lg:tw-grid lg:tw-grid-cols-3 lg:tw-gap-4">
        <article>
          <div
            class="tw-mb-4 tw-flex tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-gray-300 tw-bg-gray-100 tw-p-12 lg:tw-h-56"
          >
            <img src="assets/img/logo/logo_pstx.png" alt="PSTX logo" class="tw-w-5/6" />
          </div>
          <p class="tw-text-sm tw-text-gray-800">PSTX is spelled as capital letters P,S,T and X</p>
        </article>
      </div>
    </section>
  </div>
</main>
