<div
  class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-[#393363] tw-bg-cover tw-bg-no-repeat lg:tw-bg-[url(src/assets/img/banner/z-founder-banner-bg.svg)]"
>
  <div class="tw-flex tw-w-full tw-max-w-[1280px] tw-flex-col tw-items-center tw-gap-4 lg:tw-py-8">
    <img
      src="/assets/img/banner/z-founder-mobile-banner.png"
      alt="UM Innovations"
      class="tw-w-full lg:tw-hidden"
    />
    <img
      src="/assets/img/banner/z-founder-banner.svg"
      alt="UM Innovations"
      class="tw-hidden tw-w-10/12 lg:tw-flex"
    />
  </div>
  <div
    class="tw-relative tw-flex tw-w-screen tw-justify-center tw-border-t tw-border-white tw-shadow-[0px_4px_32px_0px_#00000029]"
  >
    <div
      class="tw-absolute tw-left-0 tw-z-0 tw-hidden tw-h-full tw-w-6/12 tw-bg-white lg:tw-flex"
    ></div>
    <div
      class="tw-z-10 tw-flex tw-w-full tw-max-w-[1280px] tw-flex-col tw-border-white lg:tw-flex-row lg:tw-justify-center"
    >
      <section
        class="tw-w-full tw-bg-white tw-p-1 lg:tw-flex lg:tw-flex-1 lg:tw-justify-end lg:tw-py-4"
      >
        <div class="tw-flex tw-flex-col tw-items-center tw-p-8 lg:tw-pl-[15px]">
          <div class="tw-max-w-[35rem] tw-text-[#262260] md:tw-max-w-[80rem] lg:tw-pl-0">
            <p
              class="tw-text-left tw-text-base tw-font-semibold tw-leading-normal tw-tracking-wide sm:tw-text-sm md:tw-text-lg"
            >
              Zfounder and pitchIN are proud to announce a new partnership aimed at empowering
              innovative startups by providing comprehensive support in fundraising and growth.
            </p>
            <p
              class="tw-text-left tw-text-base tw-font-normal tw-leading-normal sm:tw-text-sm md:tw-text-lg"
            >
              Through this collaboration, Zfounder’s network of founders, business owners and
              pitchIN’s crowdfunding platform will offer entrepreneurs the tools and resources to
              connect with investors and access to funding. Together, we are committed to fostering
              the next generation of successful startups and driving meaningful impact in the
              entrepreneurial ecosystem.
            </p>
          </div>
        </div>
      </section>
      <section
        class="tw-flex tw-flex-col tw-items-center tw-border-t tw-border-t-[#1C4597] tw-bg-white tw-p-6 tw-text-[#1C4597] lg:tw-max-w-[20rem] lg:tw-items-start lg:tw-justify-start lg:tw-bg-transparent lg:tw-py-12 lg:tw-pl-14 lg:tw-text-white"
      >
        <p
          class="tw-mb-1 tw-text-center tw-text-base md:tw-text-lg lg:tw-text-left lg:tw-text-lg lg:tw-font-thin"
        >
          Ready to take your startup to the next level?
        </p>
        <p class="tw-text-xl tw-font-semibold tw-tracking-wide lg:tw-text-lg">Discover how.</p>
        <a
          class="tw-flex tw-w-full tw-cursor-pointer tw-justify-center tw-rounded-md tw-bg-[#EF4035] tw-p-4 tw-text-lg tw-font-semibold tw-text-white tw-shadow-md hover:tw-text-white lg:tw-mt-4 lg:tw-px-4 lg:tw-py-2"
          href="https://forms.gle/2jwK53FkTAknQhre6"
          target="_blank"
        >
          Book a session with us
        </a>
      </section>
    </div>
  </div>
</div>

<section class="section" *ngIf="totalCampaigns === null">
  <div class="container-fluid">
    <agmo-loading></agmo-loading>
  </div>
</section>

<!-- Section: Coming soon (when no campaign exist) -->
<section
  class="section tw-flex tw-min-h-96 tw-flex-col tw-items-center tw-justify-center"
  *ngIf="totalCampaigns !== null && totalCampaigns === 0"
>
  <h2 class="tw-text-sm tw-font-bold md:tw-text-lg">No available campaigns yet</h2>
  <div class="tw-text-sm md:tw-text-lg">All available campaigns will be shown here</div>
</section>

<!-- Section: Pre-Live -->
<ng-container *ngIf="list[CampaignStatus.PreLive].total > 0">
  <hr class="tw-my-0" />

  <section class="section">
    <div class="container-fluid">
      <h2 class="font-weight-extrabold tw-mb-5 tw-text-xl tw-text-[#262260] md:tw-text-3xl">
        {{ 'common.preLiveCampaigns' | translate }}
      </h2>
      <app-campaign-list
        [campaigns]="list[CampaignStatus.PreLive].campaigns"
        [params]="list[CampaignStatus.PreLive].params"
        [total]="list[CampaignStatus.PreLive].total"
        [isLoading]="list[CampaignStatus.PreLive].isLoading"
        [noRecordText]="'common.comingSoon'"
        (getCampaignList)="getList($event, CampaignStatus.PreLive)"
      ></app-campaign-list>
    </div>
  </section>
</ng-container>

<!-- Section: Live -->
<section class="section" *ngIf="list[CampaignStatus.Live].total > 0">
  <div class="container-fluid">
    <h2 class="font-weight-extrabold tw-mb-5 tw-text-xl tw-text-[#262260] md:tw-text-3xl">
      {{ 'common.liveCampaigns' | translate }}
    </h2>
    <app-campaign-list
      [campaigns]="list[CampaignStatus.Live].campaigns"
      [params]="list[CampaignStatus.Live].params"
      [total]="list[CampaignStatus.Live].total"
      [isLoading]="list[CampaignStatus.Live].isLoading"
      [noRecordText]="'common.comingSoon'"
      (getCampaignList)="getList($event, CampaignStatus.Live)"
    ></app-campaign-list>
  </div>
</section>

<!-- Section: Success -->
<ng-container *ngIf="list[CampaignStatus.Success].total > 0">
  <hr class="tw-my-0" />

  <section class="section">
    <div class="container-fluid">
      <h2 class="font-weight-extrabold tw-mb-5 tw-text-xl tw-text-[#262260] md:tw-text-3xl">
        {{ 'campaign.SuccessFundedTitle' | translate }}
      </h2>
      <app-campaign-list
        [campaigns]="list[CampaignStatus.Success].campaigns"
        [params]="list[CampaignStatus.Success].params"
        [total]="list[CampaignStatus.Success].total"
        [isLoading]="list[CampaignStatus.Success].isLoading"
        [noRecordText]="'common.comingSoon'"
        (getCampaignList)="getList($event, CampaignStatus.Success)"
      ></app-campaign-list>
    </div>
  </section>
</ng-container>

<section
  class="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-bg-[#262260] tw-p-8 md:tw-gap-8 md:tw-p-16"
>
  <h3 class="tw-text-xl tw-font-bold tw-tracking-wide tw-text-white md:tw-text-4xl">
    About ZFounder
  </h3>
  <div class="tw-flex tw-max-w-[35rem] tw-flex-col md:tw-max-w-4xl">
    <p class="tw-text-left tw-text-base tw-text-white md:tw-text-lg">
      ZFounderclub is a leading MSME community in Malaysia, with a growing social media following of
      60,000 as of January 2025. Established in October 2023 by Jin Chi and Ivan Neo under ZF
      Innovations Sdn Bhd, the founding team comprises the expertise of an entrepreneur and a former
      banker. Zfounder aims to tackle critical gap in business literacy and resources for micro,
      small, and medium enterprises (MSMEs) in Malaysia.
    </p>
    <p class="tw-text-left tw-text-base tw-text-white md:tw-text-lg">
      ZFounderclub strives to foster sustainable, impactful businesses. Guided by their motto, "For
      founders, by founders," Their offerings span from learning initiatives, business incubation,
      and fundraising support to empower MSMEs at every stage of their growth journey.
    </p>
  </div>

  <a
    href="http://zfounderclub.com/"
    target="_blank"
    class="tw-rounded-full tw-bg-[#DF4C26] tw-px-6 tw-py-4 tw-text-base tw-font-semibold tw-text-white md:tw-text-lg"
    >More on ZFounder</a
  >
</section>
