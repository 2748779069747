<div class="tw-relative tw-rounded tw-bg-divider/80 tw-p-10 tw-text-center">
  <button
    (click)="close()"
    class="tw-absolute tw-right-0 tw-top-0 tw-cursor-pointer tw-bg-transparent tw-p-2.5 tw-opacity-50 hover:tw-opacity-80"
  >
    <p-icon name="close"></p-icon>
  </button>
  <form [formGroup]="form" (ngSubmit)="submit()" [class.show-form-error]="showFormError">
    <div class="tw-mb-4">
      <h1 class="p-text-h3">{{ surveyFormFields?.title }}</h1>
      <p class="p-text-p tw-mt-2.5">{{ surveyFormFields?.description }}</p>
    </div>

    <div *ngFor="let item of surveyFormFields?.reason; let i = index">
      <label
        class="radio-btn tw-w-full tw-rounded tw-border tw-border-divider tw-bg-white tw-px-4 tw-py-2"
        [class.selected]="form?.value?.reason === item.label"
      >
        <input
          formControlName="reason"
          type="radio"
          [value]="item.label"
          (change)="handleValueChange(item.label)"
        />
        <span>{{ item.label }}</span>
      </label>
    </div>
    <app-validation-error-message
      [inputName]="'Reason' | translate"
      [showFormError]="showFormError"
      [control]="form.get('reason')"
    >
    </app-validation-error-message>

    <div
      *ngIf="surveyFormFields?.include_other_reason && form?.value?.reason === 'Other'"
      class="tw-mt-2.5"
    >
      <textarea
        formControlName="otherReason"
        class="form-control !tw-h-[100px] tw-resize-none !tw-border-divider"
        placeholder="Tell me more..."
      ></textarea>
    </div>

    <button type="submit" class="btn btn-green tw-mt-5 tw-w-full">
      {{ 'common.submit' | translate }}
    </button>
  </form>
</div>
