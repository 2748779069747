<div class="container-fluid">
  <div
    class="tw-mb-0 tw-grid tw-list-none tw-gap-5 tw-pl-0 md:tw-grid-cols-2 md:tw-gap-[30px] xl:tw-grid-cols-4"
  >
    <ng-container *ngFor="let item of menu">
      <ng-container *ngIf="!item.show || item.show()">
        <a
          *ngIf="!item.isExternal"
          [routerLink]="item.url"
          class="menu-link tw-flex"
          [ngClass]="item.className"
        >
          <ng-container *ngTemplateOutlet="menuItem; context: { item: item }"></ng-container>
        </a>
        <a
          *ngIf="item.isExternal"
          [href]="item.url"
          class="menu-link tw-flex"
          [ngClass]="item.className"
        >
          <ng-container *ngTemplateOutlet="menuItem; context: { item: item }"></ng-container>
        </a>
      </ng-container>
    </ng-container>
  </div>
</div>
​
<ng-template #menuItem let-item="item">
  <div class="menu-content">
    <div
      [class.tw-text-ecf]="item.name === 'ecf'"
      [class.tw-text-primary]="item.name === 'pstx'"
      [class.tw-text-ieo]="item.name === 'tcf'"
      [class.tw-text-shariah]="item.name === 'shariah'"
    >
      <span class="p-text-tag tw-font-bold tw-uppercase">
        {{ item.name }}
      </span>
    </div>
    <div class="tw-mb-1.5 tw-text-xl tw-font-bold tw-text-black">
      {{ 'menu.' + item.name + 'Title' | translate }}
    </div>
    <div class="tw-mb-2 tw-text-body">{{ item.description | translate }}</div>
    <div class="menu-arrow tw-text-xl tw-text-primary">→</div>
  </div>
  <div class="menu-right">
    <div class="menu-icon">
      <i
        class="pitchin-icon tw-inline-block tw-h-20 tw-w-20 md:tw-h-14 md:tw-w-14 lg:tw-h-20 lg:tw-w-20"
        [ngClass]="item.icon"
      ></i>
    </div>
    <div
      class="menu-blob-shape"
      [class.tw-text-ecf-light]="item.name === 'ecf'"
      [class.tw-text-pstx-light]="item.name === 'pstx'"
      [class.tw-text-ieo-light]="item.name === 'tcf'"
      [class.tw-text-shariah-light]="item.name === 'shariah'"
    >
      <svg
        width="198"
        height="179"
        viewBox="0 0 198 179"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M296.554 186.008C296.554 186.008 324.954 150.017 296.148 105.285C269.437 63.802 228.089 83.702 202.021 45.628C135.974 -50.844 -5.01198 23.319 16.141 105.31C26.378 144.988 -15.013 146.43 6.07702 186.092L296.554 186.008Z"
          fill="currentColor"
        />
      </svg>
    </div>
  </div>
</ng-template>
