<section class="section">
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12">
        <h2 class="title font-weight-bold text-dark text-center">
          {{ 'landing.business' | translate }} {{ 'landing.directory' | translate }}
        </h2>
      </div>
    </div>

    <div class="row" *ngIf="!isLoading">
      <div
        class="col-lg-4 col-md-12 mb-3"
        *ngFor="let businessDirectory of businessDirectories; let i = index"
      >
        <div class="box">
          <div class="img-panel mx-auto mb-2">
            <img
              [src]="businessDirectory.companyLogoUrl || defaultLogo"
              [alt]="businessDirectory.brandName"
            />
          </div>
          <div class="tw-mt-3">
            <app-tag-list [tags]="businessDirectory.sectors"></app-tag-list>
          </div>
          <div class="directory-text-panel">
            <div class="title line-clamp line-clamp-2">{{ businessDirectory.brandName }}</div>
            <div class="desc line-clamp line-clamp-2">{{ businessDirectory.tagline }}</div>
          </div>
          <div class="mt-auto">
            <a
              class="btn btn-green btn-block"
              [routerLink]="'/businesses/details/' + businessDirectory.slug"
            >
              {{ 'common.viewDetails' | translate }}
            </a>
            <div
              class="btn btn-blue d-flex justify-content-center mt-3"
              *ngIf="showNotify && businessDirectory.isSubscribed"
              (click)="
                $event.stopPropagation(); $event.preventDefault(); notifyMe(businessDirectory)
              "
            >
              <div class="pitchin-icon pitchin-icon-bell-icon-blue"></div>
              <div class="text">{{ 'landing.notifyMe' | translate }}</div>
            </div>
            <div
              class="btn btn-lightgrey d-flex justify-content-center mt-3"
              *ngIf="showNotify && !businessDirectory.isSubscribed"
              (click)="
                $event.stopPropagation(); $event.preventDefault(); notifyMe(businessDirectory)
              "
            >
              <div class="pitchin-icon pitchin-icon-notification"></div>
              <div class="text">{{ 'landing.unsubscribed' | translate }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="total > params.take">
      <div class="col-lg-12">
        <div class="paging mt-3">
          <ngb-pagination
            [collectionSize]="total"
            [(pageSize)]="params.take"
            [(page)]="params.currentPage"
            (pageChange)="getList()"
            [maxSize]="5"
            [rotate]="true"
          >
            <ng-template ngbPaginationPrevious><i class="fa fa-chevron-left"></i></ng-template>
            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
            <ng-template ngbPaginationNext><i class="fa fa-chevron-right"></i></ng-template>
          </ngb-pagination>
        </div>
      </div>
    </div>
    <agmo-loading *ngIf="isLoading"></agmo-loading>
  </div>
</section>
