<div *ngIf="!isLoading; else loading">
  <div [class]="isPage ? 'container-fluid pt-4 pb-4' : ''">
    <div class="tw-mb-5 tw-items-start" [ngClass]="isInvitedToInvest ? '' : ''">
      <div class="tw-grid tw-gap-2.5 md:tw-grid-cols-4 md:tw-gap-5 lg:tw-grid-cols-6">
        <div class="text-primary text-underline cursor-pointer" (click)="back()" *ngIf="isPage">
          {{ 'common.back' | translate }}
        </div>
        <h4
          class="font-weight-extrabold text-black tw-mb-0 md:tw-col-span-2 md:tw-text-center lg:tw-col-span-4"
          [ngClass]="!isPage ? 'md:!tw-text-left' : ''"
        >
          {{ campaign?.name }} {{ 'campaign.investors' | translate }}
        </h4>
        <div
          class="md:tw-justify-self-end"
          *ngIf="campaign?.isCreator"
          [class.lg:tw-col-span-2]="!isPage"
        >
          <button
            type="button"
            class="btn btn-primary-border"
            (click)="getCampaignInvestorList(true)"
          >
            {{ 'common.export' | translate }}
          </button>
        </div>
      </div>
      <ng-container *ngIf="isInvitedToInvest; else inviteOnly">
        <div *ngIf="!isLoading">
          <div class="row d-none d-lg-flex investor-list" *ngIf="!campaign?.isCreator">
            <div
              class="col-md-4 user-info text-uppercase font-weight-bold font-size-smaller"
              [class.col-lg-8]="!campaign?.isCreator"
            >
              {{ 'campaign.investors' | translate }}
            </div>
            <div
              class="col-md-4 investor-info text-uppercase font-weight-bold font-size-smaller"
              [class.col-lg-4]="!campaign?.isCreator"
              [class.lg:!tw-text-right]="!campaign?.isCreator"
            >
              {{ 'portfolio.totalInvested' | translate }}
            </div>
          </div>

          <div class="row d-none d-lg-flex investor-list" *ngIf="campaign?.isCreator">
            <div class="col-md-3 user-info text-uppercase font-weight-bold font-size-smaller">
              {{ 'campaign.investors' | translate }}
            </div>
            <div class="col-md-3 investor-info text-uppercase font-weight-bold font-size-smaller">
              {{ 'portfolio.totalInvestedRM' | translate }}
            </div>
            <div class="col-md-3 investor-info text-uppercase font-weight-bold font-size-smaller">
              {{ 'portfolio.dateInvested' | translate }}
            </div>
            <div class="col-md-3 investor-info text-uppercase font-weight-bold font-size-smaller">
              {{ 'common.status' | translate }}
            </div>
          </div>

          <div *ngFor="let investment of investments">
            <div class="row investor-panel" *ngIf="!campaign?.isCreator">
              <div class="col-lg-4 user-info d-flex" [class.col-lg-8]="!campaign?.isCreator">
                <img
                  [alt]="investment.user?.fullName"
                  [src]="investment.user?.photoAttachmentUrl || defaultProfileImage"
                  class="investor-image"
                />
                <span class="text-black font-size-regular align-middle">
                  {{ investment.user?.fullName || ('investment.anonymous' | translate) }}
                </span>
              </div>
              <div class="col-lg-4 investor-info" [class.lg:!tw-text-right]="!campaign?.isCreator">
                <div class="text-uppercase font-weight-bold font-size-smaller d-lg-none">
                  {{ 'portfolio.totalInvested' | translate }}
                </div>
                <div class="text-primary font-weight-bold font-size-regular">
                  {{ investment.pledgedAmount | numberSuffix }}
                </div>
                <div class="text-gray font-size-smaller">
                  {{ investment.createdAt | amDateFormat: 'D MMM YYYY' }}
                </div>
              </div>
            </div>

            <div class="row investor-panel" *ngIf="campaign?.isCreator">
              <div class="col-lg-3 user-info d-flex">
                <img
                  [alt]="investment.user?.fullName"
                  [src]="investment.user?.photoAttachmentUrl || defaultProfileImage"
                  class="investor-image"
                />
                <span class="text-black font-size-regular align-middle">
                  {{ investment.user?.fullName || ('investment.anonymous' | translate) }}
                </span>
              </div>
              <div class="col-lg-3 investor-info">
                <div class="text-uppercase font-weight-bold font-size-smaller d-lg-none">
                  {{ 'portfolio.totalInvested' | translate }}
                </div>
                <div class="text-primary font-weight-bold font-size-regular">
                  {{ investment.pledgedAmount | numberSuffix }}
                </div>
              </div>
              <div class="col-lg-3 investor-info">
                <div class="text-uppercase font-weight-bold font-size-smaller d-lg-none">
                  {{ 'portfolio.dateInvested' | translate }}
                </div>
                <div class="text-grey font-size-smaller">
                  {{ investment.createdAt | amDateFormat: 'D MMM YYYY' }}
                </div>
              </div>
              <div class="col-lg-3 investor-info">
                <div class="text-uppercase font-weight-bold font-size-smaller d-lg-none">
                  {{ 'common.status' | translate }}
                </div>
                <div
                  class="text-primary font-weight-bold font-size-regular tw-text-xs"
                  [class.!tw-text-secondary]="investment.investmentStatus === 2"
                  [class.!tw-text-warning]="investment.investmentStatus === 6"
                >
                  {{ 'investment.' + InvestmentStatus[investment.investmentStatus] | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div
            class="pb-5 text-center"
            *ngIf="!isLoading && (!investments || investments.length == 0)"
          >
            {{ 'common.noRecord' | translate }}
          </div>
        </div>

        <div class="tw-inline-block tw-w-full">
          <div *ngIf="total > params.take">
            <div class="paging mt-3">
              <ngb-pagination
                [collectionSize]="total"
                [(pageSize)]="params.take"
                [(page)]="params.currentPage"
                (pageChange)="getCampaignInvestorList(false)"
                [maxSize]="5"
                [rotate]="true"
              >
                <ng-template ngbPaginationPrevious><i class="fa fa-chevron-left"></i></ng-template>
                <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                <ng-template ngbPaginationNext><i class="fa fa-chevron-right"></i></ng-template>
              </ngb-pagination>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #inviteOnly>
  <app-empty-state
    [title]="'common.inviteOnlyEmptyStateTitle'"
    [message]="'common.inviteOnlyEmptyStateDescription'"
    [ctaUrl]="campaign?.registrationLinkUrl"
    [ctaText]="'common.registerInterest'"
  >
  </app-empty-state>
</ng-template>

<ng-template #loading>
  <agmo-loading></agmo-loading>
</ng-template>
